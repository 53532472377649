@mixin use-msr-icon($icon) {
  font-family: "Material Symbols Rounded";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased; // Support for all WebKit browsers
  -moz-osx-font-smoothing: grayscale; // Support for Firefox
  text-rendering: optimizeLegibility; // Support for Safari and Chrome
  font-feature-settings: "liga"; // Support for IE
  vertical-align: middle;

  &:before {
    content: $icon;
  }
}

.msr-icon-fw {
  width: 1.28571429em;
  text-align: center;
}

.msr-icon-import_contacts {
  @include use-msr-icon("import_contacts");
}

.msr-icon-dashboard {
  @include use-msr-icon("dashboard");
}

.msr-icon-credit_card {
  @include use-msr-icon("credit_card");
}

.msr-icon-account_box {
  @include use-msr-icon("account_box");
}

.msr-icon-power_settings_new {
  @include use-msr-icon("power_settings_new");
}

.msr-icon-settings {
  @include use-msr-icon("settings");
}

.msr-icon-edit {
  @include use-msr-icon("edit");
}

.msr-icon-add {
  @include use-msr-icon("add");
}
.msr-icon-add_circle {
  @include use-msr-icon("add_circle");
}

.msr-icon-close {
  @include use-msr-icon("close");
}

.msr-icon-done {
  @include use-msr-icon("done");
}

.msr-icon-group-add {
  @include use-msr-icon("group-add");
}

.msr-icon-visibility {
  @include use-msr-icon("visibility");
}

.msr-icon-attach_file {
  @include use-msr-icon("attach_file");
}
.msr-icon-file_copy {
  @include use-msr-icon("file_copy");
}
.msr-icon-draft {
  @include use-msr-icon("draft");
}
.msr-icon-draw {
  @include use-msr-icon("draw");
}
.msr-icon-list_alt {
  @include use-msr-icon("list_alt");
}
.msr-icon-manage_search {
  @include use-msr-icon("manage_search");
}

.msr-icon-draw {
  @include use-msr-icon("draw");
}

.msr-icon-chat {
  @include use-msr-icon("chat");
}

.msr-icon-inventory-2 {
  @include use-msr-icon("inventory_2");
}

.msr-icon-inbox {
  @include use-msr-icon("inbox");
}

.msr-icon-send {
  @include use-msr-icon("send");
}

.msr-icon-inventory {
  @include use-msr-icon("inventory");
}
.msr-icon-inventory_2 {
  @include use-msr-icon("inventory_2");
}

.msr-icon-delete {
  @include use-msr-icon("delete");
}

.msr-icon-fullscreen {
  @include use-msr-icon("fullscreen");
}

.msr-icon-event_busy {
  @include use-msr-icon("event_busy");
}
.msr-icon-close_fullscreen {
  @include use-msr-icon("close_fullscreen");
}

.msr-icon-folder {
  @include use-msr-icon("folder");
}

.msr-icon-cloud_download {
  @include use-msr-icon("cloud_download");
}

.msr-icon-download {
  @include use-msr-icon("download");
}

.msr-icon-preview {
  @include use-msr-icon("preview");
}

.msr-icon-navigate_next {
  @include use-msr-icon("navigate_next");
}

.msr-icon-navigate_before {
  @include use-msr-icon("navigate_before");
}

.msr-icon-more_vert {
  @include use-msr-icon("more_vert");
}

.msr-icon-person {
  @include use-msr-icon("person");
}

.msr-icon-check_circle {
  @include use-msr-icon("check_circle");
}
.msr-icon-visibility {
  @include use-msr-icon("visibility");
}

.msr-icon-campaign {
  @include use-msr-icon("campaign");
}

.msr-icon-workspaces {
  @include use-msr-icon("workspaces");
}

.msr-icon-info {
  @include use-msr-icon("info");
}

.msr-icon-warning {
  @include use-msr-icon("warning");
}

.msr-icon-error {
  @include use-msr-icon("error");
}

.msr-icon-event {
  @include use-msr-icon("event");
}

.msr-icon-receipt_long {
  @include use-msr-icon("receipt_long");
}

.msr-icon-notifications {
  @include use-msr-icon("notifications");
}

.msr-icon-refresh {
  @include use-msr-icon("refresh");
}

.msr-icon-mail {
  @include use-msr-icon("mail");
}

.msr-icon-drafts {
  @include use-msr-icon("drafts");
}

.msr-icon-search {
  @include use-msr-icon("search");
}

.msr-icon-device_reset {
  @include use-msr-icon("device_reset");
}

.msr-icon-do_not_disturb_on {
  @include use-msr-icon("do_not_disturb_on");
}

.msr-icon-tune {
  @include use-msr-icon("tune");
}

.msr-icon-expand_more {
  @include use-msr-icon("expand_more");
}

.msr-icon-reply {
  @include use-msr-icon("reply");
}
.msr-icon-subject {
  @include use-msr-icon("subject");
}
.msr-icon-smartphone {
  @include use-msr-icon("smartphone");
}

.msr-icon-cancel {
  @include use-msr-icon("cancel");
}

$icon-sizes: 10px, 12px, 14px, 16px, 18px, 20px, 22px, 24px, 26px, 28px, 30px, 1rem, 2rem, 3rem;

@each $size in $icon-sizes {
  .icon-#{$size} {
    font-size: #{$size} !important;
  }
}

.icon-sm {
  font-size: 1rem;
}

.icon-xs {
  font-size: 0.75rem;
}
