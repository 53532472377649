.editor-container .ql-toolbar {
  background: var(--surface-c);
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}
.editor-container .ql-toolbar.ql-snow {
  border-top: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color); // Won't change event if the input is invalid
}
.editor-container .ql-toolbar.ql-snow .ql-stroke {
  stroke: var(--text-color-secondary);
  stroke-width: 1.5;
}
.editor-container .ql-toolbar.ql-snow .ql-fill {
  fill: var(--text-color-secondary);
}
.editor-container .ql-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: var(--text-color-secondary);
}
.editor-container .ql-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: var(--text-color);
}
.editor-container .ql-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: var(--text-color);
}
.editor-container .ql-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: var(--text-color);
}
.editor-container .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: var(--text-color);
}
.editor-container .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: var(--text-color);
}
.editor-container .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: var(--text-color);
}
.editor-container .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: var(--surface-0);
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: var(--border-radius);
  padding: 0.25rem 0;
}
.editor-container .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: var(--text-color);
}
.editor-container .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: var(--text-hover-color);
  background: var(--background-hover-color);
}
.editor-container .ql-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.75rem 1rem;
}
.editor-container .ql-container {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.editor-container .ql-container.ql-snow {
  border: 1px solid var(--border-color);
}
.editor-container .ql-container .ql-editor {
  background: var(--surface-card);
  color: var(--text-color);
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  // Contraints applied by default - Adapted through the following variables
  min-height: var(--message-editor-min-height, 8rem);
  max-height: var(--message-editor-max-height, 10rem);
}
.editor-container .quill-disabled .ql-container .ql-editor {
  opacity: 0.6;
}
.editor-container .ql-snow.ql-toolbar button:hover,
.editor-container .ql-snow.ql-toolbar button:focus {
  color: var(--text-color);
}
.editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: var(--text-color);
}
.editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: var(--text-color);
}
.editor-container .ql-snow.ql-toolbar button.ql-active,
.editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary-color);
}
.editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: var(--primary-color);
}
.editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: var(--primary-color);
}
.editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: var(--primary-color);
}
.editor-container .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  width: auto;
  height: auto;
}

.ql-mention-list-container {
  max-height: 300px;
  width: auto;
  max-width: 20rem;
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-1);
  border-radius: var(--border-radius);
}
.ql-mention-list-item {
  cursor: pointer;
  line-height: 16px;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  vertical-align: middle;

  &.selected {
    background-color: rgba(var(--primary-color-rgb), 0.05);
  }
}

.editor-container .ql-snow {
  .ql-tooltip {
    border-radius: 2px;
    font-family: var(--font-family);
    color: var(--text-color);
    box-shadow: var(--shadow-1);
    border: 1px solid var(--gray-300);
    border-radius: 4px;
    a {
      color: var(--nestor-primary);
    }
    input[type="text"] {
      border-radius: 2px;
      &:focus {
        --color-focus-shadow: var(--nestor-100);
        --color-focus: var(--nestor-primary);
        box-shadow: 0 0 0 0.2rem var(--color-focus-shadow);
        outline: 1px solid var(--color-focus);
        outline-offset: -1px;
      }
    }
  }
}
