.provider-file-picker {
  --panel-margin: 0.5rem;
  --side-panel-minimum-width: 15rem;
  --side-panel-maximum-width: 288px;
  --responsive-side-width: minmax(var(--side-panel-minimum-width), var(--side-panel-maximum-width));

  height: 100%;
  min-height: 60vh;
  display: grid;
  grid-template-columns: var(--responsive-side-width) 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "roots items";

  .provider-root-list__selection {
    --tree-background: transparent;
    grid-area: roots;
    position: sticky;
    top: 4rem;
    height: max-content;

    .root-item-dialog__list {
      border-width: 0px 0px 0px 0px;
      overflow: auto;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 100%;
      padding: 0;
      border-radius: 0;
      border-color: var(--surface-border);
      background: var(--tree-background);

      .root-item {
        width: 100%;

        .root-item__icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }

        .root-item__name {
          font-weight: 400;
          color: var(--text-color);
        }

        .root-item__information {
          .root-item__format {
            color: var(--text-color-secondary);
          }
        }
      }

      .p-tree-wrapper {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: var(--panel-margin, 1rem);
      }
      .p-tree-empty-message {
        padding: var(--panel-margin, 1rem);
      }

      .p-tree-filter-container {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 0;
        background-color: var(--surface-card);
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--border-color);
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1;
        width: auto;
        height: var(--panel-header-height);
        .p-tree-filter {
          padding-top: 8px;
          padding-bottom: 8px;
          font-size: 14px;
          height: 36px;
          line-height: 20px;
        }
        .p-tree-filter-icon {
          right: 1.75rem;
        }
      }

      .p-treenode-children {
        padding: 0 0 0 2rem;

        p-treenode {
          .p-treenode-leaf {
            text-align: left;

            .p-treenode-content {
              &:focus {
                box-shadow: none;
              }
              &:focus-visible {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: inset 0 0 0 0.15rem var(--color-focus);
              }
              &.p-highlight {
                border: 1px solid var(--primary-color);
                background-color: var(--background-hover-color);
                color: inherit;
                font-weight: inherit;
              }
              .p-treenode-label {
                width: 100%;
              }
            }

            .p-tree-toggler {
              display: none;
            }
          }
        }
      }

      .p-treenode {
        padding: 0;

        // &:not(.p-treenode-leaf) {
        //   // This target the header of each data types
        //   > .p-treenode-content {
        //     background-color: var(--surface-header);
        //     padding: 1rem 0.825rem 0.625rem 0.825rem;
        //     pointer-events: none;
        //     .p-tree-toggler {
        //       display: none;
        //     }
        //     .p-treenode-label {
        //       font-size: 0.825rem;
        //       font-weight: 600;
        //       color: var(--text-color-secondary);
        //       text-transform: uppercase;
        //     }
        //   }
        // }

        .p-treenode-content {
          border-radius: 0;
          padding: 0.6rem;
          border-left: 4px solid;
          &:not(.p-highlight) {
            border-color: transparent;
          }
          &:focus {
            box-shadow: none;
          }
          &:focus-visible {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem var(--color-focus);
          }

          .p-tree-toggler {
            width: 1.5rem;
            height: 1.5rem;

            svg {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }
  }

  .provider-item-list__selection {
    grid-area: items;
    border-left: 1px solid var(--surface-border);
    padding-left: 0;
  }
}
