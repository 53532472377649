/**
  Editor styles
*/
quill-editor {
  display: block !important;
}
// Quill editor wrapper
.editor-container {
  margin: 1px;
  .ql-form-item {
    --form-items-background-color: var(--fi-default-background);
    --form-items-color: var(--fi-default-text);
    --form-items-border-color: var(--fi-default-border-color);
    display: inline-block;
    background-color: var(--form-items-background-color, var(--surface-card));
    color: var(--form-items-color, var(--text-color));
    border-radius: var(--fi-border-radius);
    line-height: 1.1;
    padding: 0.1rem 0.3rem;
    user-select: none;
    cursor: pointer;
    border: 1px dashed;
    border-color: var(--form-items-border-color);
    transition: all 300ms;

    > span[contenteditable] {
      vertical-align: text-top;
    }
    &:hover {
      --form-items-color: var(--text-color);
      --form-items-border-color: var(--text-color);
    }
  }

  .ql-editor {
    ol,
    ul {
      padding-left: 1rem;

      & > li {
        margin-bottom: 2px;

        &:not(.ql-direction-rtl) {
          padding-left: 1rem;
        }
      }
    }
  }
}

/**
    Message editor styles
  */
.message-editor {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

  .ql-container {
    background-color: var(--surface-card);
  }

  &__input {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: var(--border-radius);
  }
  &--no-border {
    editor-record-message-editor,
    editor-record-message-editor .ql-container,
    editor-record-message-editor .ql-container:focus {
      border: none !important;
      box-shadow: none !important;
    }
    editor-record-message-editor .ql-toolbar {
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      box-shadow: none !important;
    }
  }

  &:focus-within {
    .message-editor__input {
      box-shadow: 0 0 0 0.2rem var(--color-focus-shadow);
    }
    .ql-toolbar.ql-snow {
      border-top-color: var(--color-focus);
      border-left-color: var(--color-focus);
      border-right-color: var(--color-focus);
    }
    .ql-container.ql-snow {
      --border-color: var(--color-focus);
    }
  }

  .ql-editor {
    font-family: var(--font-family);
    font-size: 1rem;

    p {
      margin: 0 0 6px 0;
    }

    a {
      color: var(--primary-color);
    }

    &.ql-blank {
      &::before {
        font-style: normal;
        color: var(--gray-400);
      }
    }
  }

  .ql-tooltip {
    &[data-mode="link"] {
      &::before {
        content: var(--ql-tooltip-label);
      }
    }

    &.ql-editing {
      a.ql-action {
        &::after {
          content: var(--ql-tooltip-action);
        }
      }
    }

    a.ql-action {
      &::after {
        content: var(--ql-tooltip-edit);
      }
    }
    a.ql-remove {
      &::before {
        content: var(--ql-tooltip-remove);
      }
    }
  }

  .ql-toolbar {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

    .ql-stroke {
      stroke: var(--text-color-secondary) !important;
    }

    .ql-fill {
      fill: var(--text-color-secondary) !important;
    }

    button {
      --editor-icon-size: 24px;
      border-radius: 4px;
      height: var(--editor-icon-size);
      margin: 2px;
      padding: 2px;
      width: var(--editor-icon-size);
      color: var(--text-color-secondary);
      font-family: var(--font-family);
      &:hover {
        background-color: var(--background-hover-color) !important;
        color: var(--primary-color) !important;
        .ql-stroke {
          stroke: var(--primary-color) !important;
        }

        .ql-fill {
          fill: var(--primary-color) !important;
        }
      }

      &.ql-active {
        background-color: var(--background-active-color) !important;

        .ql-stroke {
          stroke: var(--primary-color) !important;
        }

        .ql-fill {
          fill: var(--primary-color) !important;
        }
      }
    }
  }

  .message-editor__reply {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--surface-200);

    .message-editor__reply-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      font-size: 0.875rem;
      color: var(--text-color);

      .message-editor__reply-content__body {
        font-style: italic;
        color: var(--text-color-secondary);
      }
    }

    .message-editor__close {
      &:hover {
        & > i {
          background: var(--surface-200);
        }
      }

      & > i {
        width: 100%;
        height: 100%;
        font-size: 1.125rem;
        color: var(--text-color-secondary);
        border-radius: 20px;
        padding: 3px;
        vertical-align: middle;
        transition: background 0.2s ease-in-out;
      }
    }
  }
}

/**
    Form items styles
  */
.data-type {
  .data-type__format {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    i {
      font-size: 1.2rem;
    }
  }

  &.data-type--boolean,
  &.data-type--rating,
  &.data-type--choice,
  &.data-type--multichoice,
  &.data-type--text,
  &.data-type--longtext,
  &.data-type--email,
  &.data-type--phone,
  &.data-type--date,
  &.data-type--datetime,
  &.data-type--time,
  &.data-type--number,
  &.data-type--currency,
  &.data-type--range,
  &.data-type--documents,
  &.data-type--address {
    .data-type__format {
      color: var(--primary-300);
    }
  }
}

.form-answer {
  .form-answer__title {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }

  .form-answer__message {
    display: grid;
    grid-template-rows: auto var(--message-fraction, 2lh) auto;
    &[aria-expanded="true"] {
      --message-fraction: 1fr;
      --message-shadow: none;
    }
    &__html {
      overflow: hidden;
      border-bottom: 1px solid var(--surface-border);
      box-shadow: var(--message-shadow, inset 0px -13px 10px -16px rgba(0, 0, 0, 0.1));
    }
  }

  .form-answer__separator {
    position: relative;
    display: flex;
    padding: 1.5rem 0;
    align-items: center;

    div {
      flex-grow: 1;
      border-top: 1px solid var(--surface-300);
    }

    span {
      flex-shrink: 1;
      margin: 0 1rem;
      color: var(--surface-400);
      font-weight: 600;
      font-size: 0.875rem;
    }
  }
}

.form-answer,
.form-item-properties {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .form-answer__content,
  .form-item-properties__content {
    .form-answer__value {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    .form-answer__buttons {
      background: linear-gradient(to top, var(--surface-card) 30%, transparent);
      backdrop-filter: blur(3px);
      border-top: 1px solid var(--surface-border-light);
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5rem;
      width: 100%;
      padding: 1rem;
      bottom: 0rem;
      left: 0rem;
      z-index: 4;
    }

    .form-answer__header {
      --separator-background-color: transparent;
      --separator-color: var(--surface-border);
      text-align: center;
      font-size: 0.875rem;
      position: relative;

      &:before {
        content: "";
        background-color: var(--separator-color);
        height: 1px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
      }

      span {
        display: inline-block;
        border-radius: 15px;
        background-color: var(--surface-overlay);
        box-shadow: 0 0 0 1px var(--separator-color);
        padding: 4px 12px;
        color: var(--text-color-secondary);
        position: relative;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.form-item-properties {
  position: relative;
  .form-item-properties__content {
    display: grid;
    grid-template-rows: 1fr auto;
    position: absolute;
    width: 100%;
    border-right: 1px solid var(--border-color);
  }
  .form-item-properties__buttons {
    background-color: var(--surface-card);
    padding: 0.875rem var(--panel-margin);
    border-top: 1px solid var(--border-color);
  }
  .form-item-properties__fields {
    padding: var(--panel-margin, 1rem);
    overflow-y: auto;
  }
  .form-item-properties__control {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    width: 100%;

    input,
    textarea,
    p-dropdown,
    .p-dropdown,
    .p-calendar,
    p-checkbox,
    p-inputnumber,
    .p-inputnumber,
    p-calendar,
    .p-calendar,
    p-listbox,
    .p-listbox {
      width: 100%;
    }

    p-inputnumber,
    .p-inputnumber {
      display: flex;
    }

    p-listbox {
      .p-listbox-list-wrapper {
        max-height: 250px;
      }
    }
  }

  .form-item-properties__control__label {
    width: 100%;
    font-weight: 600;
    font-size: 1rem;
  }

  .form-item-properties__control__description {
    color: var(--text-color-secondary);
    margin: 0;
    font-size: 0.875rem;
  }
}

.form-item__control {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;

  input,
  textarea,
  .p-dropdown,
  .p-calendar,
  p-inputnumber,
  .p-inputnumber,
  p-checkbox {
    width: 100%;
  }

  .p-inputswitch {
    display: block;
  }

  .form-item__control__address {
    padding: 1rem;
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    border-radius: var(--border-radius);
  }

  .form-item__control__label {
    width: 100%;
    font-weight: 600;
    font-size: 1rem;
  }

  .form-item__control__actions {
    .data-type__format {
      justify-content: flex-end;
    }

    .p-dropdown-trigger {
      width: 1.5rem;
      justify-content: flex-end;
    }

    .form-item {
      &--valid,
      &--valid .p-dropdown-label,
      &--valid .p-dropdown-trigger {
        color: var(--green-600);
      }

      &--invalid,
      &--invalid .p-dropdown-label,
      &--invalid .p-dropdown-trigger {
        color: var(--red-600);
      }
    }
  }

  .form-item__control__description {
    color: var(--text-color-secondary);
    margin: 0;
    font-size: 0.875rem;
    & > * {
      margin: 0;
    }
  }

  .form-item__answer {
    position: relative;

    &:not(.form-item__answer--documents) {
      --border-radius: 15px;
      background-color: var(--surface-card);
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius);
      color: var(--text-color);
      padding-left: 0.875rem;
      padding-top: 0.875rem;
      padding-bottom: 0.875rem;
      padding-right: 0.875rem;
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}

.data-type-dialog {
  --sidebar-content-padding: 0 !important;
  --sidebar-footer-padding: 0 !important;
  border-radius: var(--border-radius);
  overflow: hidden;

  .data-type-dialog__container {
    // There are 3 panels inside a grid (selectionList, configuration, preview)
    --panel-margin: 1rem;
    --side-panel-minimum-width: 18rem;
    --side-panel-maximum-width: 576px;
    --content-panel-minimum-width: 600px;
    --panel-header-height: 68px;
    --selection-panel-height: 23rem;
    --responsive-side-width: minmax(var(--side-panel-minimum-width), var(--side-panel-maximum-width));
    height: 100%;
    min-height: 70vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--selection-panel-height) var(--panel-header-height) 1fr;
    grid-template-areas:
      "selection selection selection"
      "header header header"
      "config config config";
    @media screen and (min-width: 768px) {
      grid-template-areas:
        "selection header header"
        "selection config preview";
      grid-template-columns: var(--responsive-side-width) minmax(var(--content-panel-minimum-width), 1fr) var(
          --responsive-side-width
        );
      grid-template-rows: var(--panel-header-height) 1fr;
    }
    &[data-grid-view-for="edition"] {
      grid-template-areas:
        "header header header"
        "config config config";
      --selection-panel-height: ;
      // grid-template-rows: var(--panel-header-height) 1fr;
      @media screen and (min-width: 768px) {
        grid-template-areas:
          "header header header"
          "config config preview";
      }
    }
    &[data-grid-view-for="selection"] {
      grid-template-rows: 1fr;
      @media screen and (min-width: 768px) {
        grid-template-areas:
          "selection preview preview"
          "selection preview preview";
      }
    }
    .data-type-dialog__preview {
      grid-area: preview;
      padding-left: var(--panel-margin, 1rem);
      padding-right: var(--panel-margin, 1rem);
      display: none;
      @media screen and (min-width: 768px) {
        display: block;
      }
    }
    .data-type-dialog__selection {
      grid-area: selection;
    }
    .data-type-dialog__header {
      grid-area: header;
      background-color: var(--surface-card);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: var(--panel-margin);
      border-top: 1px solid var(--border-color);
      z-index: 1;
      border-bottom: 1px solid var(--border-color);
      @media screen and (min-width: 768px) {
        border-top: none;
      }
    }
    .data-type-dialog__config {
      grid-area: config;
      background-color: var(--surface-card);
      z-index: 1;
    }
  }

  .data-type-dialog__selection {
    position: relative;
    .data-type-dialog__list {
      border-width: 0px 1px 0px 0px;
      overflow: auto;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 100%;
      padding: 0rem 0rem 3rem 0rem;
      border-radius: 0;
      border-color: var(--border-color);
      background-color: var(--treenode-selection-background, var(--surface-c));
      box-shadow: inset 0px -8px 8px -10px rgba(0, 0, 0, 0.4);
      @media screen and (min-width: 768px) {
        --treenode-selection-background: var(--surface-card);
        box-shadow: none;
      }
      .data-type {
        width: 100%;

        i {
          font-size: 24px;
          color: var(--primary-color);
        }

        .data-type__information {
          .data-type__format {
            color: var(--text-color-secondary);
          }
        }
      }

      .p-tree-wrapper {
        padding-left: var(--panel-margin, 1rem);
        padding-right: var(--panel-margin, 1rem);
      }
      .p-tree-empty-message {
        padding: var(--panel-margin, 1rem);
      }

      .p-tree-filter-container {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 0;
        background-color: var(--surface-card);
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--border-color);
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1;
        width: auto;
        height: var(--panel-header-height);
        .p-tree-filter {
          padding-top: 8px;
          padding-bottom: 8px;
          font-size: 14px;
          height: 36px;
          line-height: 20px;
        }
        .p-tree-filter-icon {
          right: 1.75rem;
        }
      }

      .p-treenode-children {
        padding: 0;
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 0.75rem;
        p-treenode {
          .p-treenode-leaf {
            text-align: left;

            .p-treenode-content {
              justify-content: start !important;
              border: 1px solid var(--border-color);
              border-radius: var(--border-radius);
              &:focus {
                box-shadow: none;
              }
              &:focus-visible {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: inset 0 0 0 0.15rem var(--color-focus);
              }
              &.p-highlight {
                border: 1px solid var(--primary-color);
                background-color: var(--background-hover-color);
                color: inherit;
                font-weight: inherit;
              }
              .p-treenode-label {
                width: 100%;
              }
            }

            .p-tree-toggler {
              display: none;
            }
          }
        }
      }

      .p-treenode {
        padding: 0;
        &:not(.p-treenode-leaf) {
          // This target the header of each data types
          > .p-treenode-content {
            background-color: var(--treenode-header-backgroung, transparent);
            padding: 1rem 0.825rem 0.625rem 0.825rem;
            pointer-events: none;
            .p-tree-toggler {
              display: none;
            }
            .p-treenode-label {
              font-size: 0.825rem;
              font-weight: 600;
              color: var(--text-color-secondary);
              text-transform: uppercase;
            }
          }
        }

        .p-treenode-content {
          border-radius: 0;
          padding: 0.5rem;
          &:focus {
            box-shadow: none;
          }
          &:focus-visible {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem var(--color-focus);
          }
        }
      }
    }
  }
}

.preview {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .preview__description {
    font-size: 0.875rem;
    color: var(--surface-500);
  }

  .preview__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid var(--surface-border);
  }

  .preview__control {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
    max-width: 400px;

    input,
    textarea,
    .p-dropdown,
    .p-calendar,
    p-inputnumber,
    .p-inputnumber,
    p-checkbox {
      width: 100%;
    }
  }
}

/*
  Specific styles
*/
.answer-dropdown {
  min-width: 0;
  max-width: 650px;

  .p-dropdown-items {
    .p-dropdown-item {
      white-space: normal;
    }
  }
}
