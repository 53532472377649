ngx-otp-input {
  --otp-input-size: 3.5rem;
  --otp-input-border: 1px solid var(--border-color);
  --otp-input-shadow-focused: 0 0 0 0.2rem var(--color-focus-shadow);
  --otp-input-border-focused: 1px solid var(--color-focus);
  --otp-input-border-radius: var(--border-radius);

  .ngx-otp-input-container {
    .ngx-otp-input {
      width: var(--otp-input-size);
      height: var(--otp-input-size);
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      border: var(--otp-input-border);
      border-radius: var(--otp-input-border-radius);

      &:focus {
        border: var(--otp-input-border-focused);
        box-shadow: var(--otp-input-shadow-focused);
      }
    }
  }
}
