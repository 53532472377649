.emoji-mart {
  font-family: var(--font-family);
}

.emoji-mart-bar {
  border: 0 solid var(--surface-100);
}

.emoji-mart-anchor {
  display: flex;
  justify-content: center;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: var(--text-color);
}

.emoji-mart-search {
  margin-bottom: 6px;

  input {
    font-size: 14px;
    line-height: 1;
    border: none;
    background-color: var(--surface-100);
    color: var(--text-color) !important;
    padding: 10px 2em 10px 2.2em;

    ::placeholder {
      color: var(--text-color-secondary);
    }

    &:focus {
      background-color: var(--surface-0);
      box-shadow: inset 0 0 0 1px var(--primary-color);
    }
  }

  .emoji-mart-search-icon {
    width: 24px;
    cursor: pointer;
    left: 10px;
    z-index: 1;
    color: var(--text-color-secondary);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.emoji-mart-scroll {
  padding-right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    &::-webkit-scrollbar {
      display: unset;
    }
  }
}

.emoji-mart-category {
  .emoji-mart-emoji {
    span {
      cursor: pointer;
    }

    &:hover {
      &:before {
        border-radius: 6px;
        background-color: var(--primary-50);
      }
    }
  }
}

.emoji-mart-category-label {
  font-family: var(--heading-font-family);
  font-size: 14px;
}
