// Use to provide consistent spacing above primary layouts
:root {
  --layout-margin: 2rem;
  --bottom-menu-safe-height: 3.825rem;
  --fluid-padding: var(--space-2xs-m, 1rem);
}

/*=============================================
=            Auto Grid Layout                 =
===============================================
↓ Automatically layout its children in a grid.
--grid-min-item-size: CSS value to change the minimum size of each item.
--grid-placement: auto-fill || auto-fit 
---------------------------------------------
<parent class="auto-grid">
  <child>...</child>
  <child>...</child>
</parent>
=============================================*/
.auto-grid {
  --grid-min-item-size: 20rem;
  --grid-placement: auto-fill;
  display: grid;
  gap: var(--space, var(--space-3xs, 0.125rem));
  grid-template-columns: 1fr;
}
/* When supported by the browser, uses the grid layout else keep a single column */
@supports (width: min(var(--grid-min-item-size), 100%)) {
  .auto-grid {
    grid-template-columns: repeat(var(--grid-placement), minmax(min(var(--grid-min-item-size), 100%), 1fr));
  }
}

/*=============================================
  =            The sidebar                     =
  ==============================================
  USAGE ↓
  <parent class="with-sidebar">
    <child class="with-sidebar__sidebar">...</child>
    <child class="with-sidebar__main">...</child>
  </parent>
  =============================================*/
.with-sidebar {
  /** ↓ SidebarWidth in px. The minimum width of the sidebar element in the horizontal configuration. Works better with fixed values */
  --sidebar-width-value: 300;
  /** ↓ ContentMin in %. The minimum width of the content element in the horizontal configuration. Prefer % values */
  --content-min-value: 65;
  /** ↓ Gap/Gutter space between the main content and the sidebar */
  --space: 1rem;
  /** ↓ If you want the sidebar to be at the bottom in the vertical configuration, set it to wrap-reverse. */
  --flex-wrap: wrap;
  /** ↓ Computed variables */
  --content-min: calc(var(--content-min-value) * 1%);
  --sidebar-width: calc(var(--sidebar-width-value) * 1px);
  display: flex;
  flex-wrap: var(--flex-wrap, wrap);
  gap: var(--space, 1rem);
  justify-content: stretch;
  /* align-items: var(--vertical-alignment, stretch);  */
  &__sidebar {
    flex-basis: var(--sidebar-width, auto);
    flex-grow: 1;
  }
  &__main {
    flex-basis: 0;
    flex-grow: 999;
    min-width: var(--content-min, 50%);
  }
}

/*=============================================
  =            Cover                            =
  ---------------------------------------------
  <parent class="cover">
    <child>...</child>
    <child class="cover__centered">...</child>
    <child>...</child>
  </parent>
  =============================================*/
.cover {
  --cover-padding: 1rem;
  --min-height: 50vh;
  --space: 1rem;
  display: flex;
  flex-direction: column;
  min-height: var(--min-height);
  /* ↓ noPad padding: 0; */
  padding: var(--cover-padding);
  > * {
    margin-top: var(--space);
    margin-bottom: var(--space);
  }
  > :first-child:not(.cover__centered) {
    margin-top: 0;
  }
  > :last-child:not(.cover__centered) {
    margin-bottom: 0;
  }
  /* ↓ .cover__centered is the centered element  */
  > .cover__centered {
    margin-top: auto;
    margin-bottom: auto;
  }
}

/*=============================================
  =            Stack           =
  =============================================
  -----------------------------------------------------------
  The Stack: Can be used for a vertical list of items
  ** uses margins instead of gaps for better browser support 
  -----------------------------------------------------------
  =============================================*/

.stack {
  --stack-space: 1.5em;
  &.gap-0 {
    --stack-space: 0;
  }
  &.gap-1 {
    --stack-space: 0.25rem;
  }
  &.gap-2 {
    --stack-space: 0.5rem;
  }
  &.gap-3 {
    --stack-space: 1rem;
  }
  &.gap-4 {
    --stack-space: 1.5rem;
  }
  &.gap-5 {
    --stack-space: 2rem;
  }
  &.gap-6 {
    --stack-space: 3rem;
  }
  &.gap-7 {
    --stack-space: 4rem;
  }
  &.gap-8 {
    --stack-space: 5rem;
  }
  > * + * {
    margin-top: var(--stack-space, 1.5rem);
  }
}

/* Variant using Grid - More flexible */
.stack-grid {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
}

/* The Switcher: alternate between vertical and horizontal configuration
  * depending on the provided threshold
  -----------------------------------------------------------------------*/
.switcher {
  --switcher-space: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: var(--switcher-space);
  > * {
    flex-grow: 1;
    flex-basis: calc((var(--threshold, 30rem) - 100%) * 999);
  }
}

/* Horizontally padded container (responisve) */
.padded-container {
  padding-right: var(--fluid-padding);
  padding-left: var(--fluid-padding);
}

/* Horizontal Constained Container used per layout depending on the contents */
.measured-container {
  box-sizing: content-box;
  max-width: var(--measure, var(--medium-measure, 100rem));
  margin-left: auto;
  margin-right: auto;
  // These tokens values are defined inside tokens.scss
  &.measure-xs {
    --measure: var(--xsmall-measure);
  }
  &.measure-sm {
    --measure: var(--small-measure);
  }
  &.measure-md {
    --measure: var(--medium-measure);
  }
  &.measure-lg {
    --measure: var(--medium-measure);
  }
}
.primary-layout {
  --layout-margin-bottom: calc(
    var(--layout-margin) + var(--bottom-menu-safe-height)
  ); // Increase to take bottom tab menu into account
  margin-top: var(--layout-margin);
  margin-bottom: var(--layout-margin-bottom);
}
@media screen and (min-width: 769px) {
  :root {
    --bottom-menu-safe-height: 0px;
  }
}
