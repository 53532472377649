@mixin listLayouts {
  @for $i from 0 through 6 {
    &.ql-indent-#{$i} {
      margin-left: #{($i * 28) + 28}px;
    }
  }
}

@mixin ulContents() {
  @for $i from 0 through 6 {
    &.ql-indent-#{$i} {
      &::before {
        @if ($i == 0 or $i % 3 == 0) {
          content: "•";
        } @else if ($i % 2 == 1) {
          content: "￮";
        } @else if ($i % 2 == 0) {
          content: "‣";
        }
      }
    }
  }
}

@mixin olContents {
  @for $i from 0 through 6 {
    &.ql-indent-#{$i} {
      &::before {
        @if ($i == 0 or $i % 3 == 0) {
          content: counter(list-#{$i}, decimal) ". ";
          counter-increment: list-#{$i};
        } @else if ($i % 2 == 1) {
          content: counter(list-#{$i}, lower-alpha) ". ";
          counter-increment: list-#{$i};
        } @else if ($i % 2 == 0) {
          content: counter(list-#{$i}, lower-roman) ". ";
          counter-increment: list-#{$i};
          text-align: right;
        }
      }
    }
  }
}

@mixin messageHtmlStyles() {
  & > * {
    margin: 0;
  }

  p {
    max-width: 100% !important;
    margin: 2px 0;
  }

  a {
    color: var(--primary-color);
  }

  .ql-form-item {
    --form-items-background-color: var(--fi-default-background);
    --form-items-color: var(--fi-default-text);
    --form-items-border-color: var(--fi-default-border-color);
    display: inline-block;
    background-color: var(--form-items-background-color, var(--surface-card));
    color: var(--form-items-color, var(--text-color));
    border-radius: var(--fi-border-radius);
    line-height: 1.1;
    padding: 0.1rem 0.3rem;
    user-select: none;
    cursor: pointer;
    border: 1px dashed;
    border-color: var(--form-items-border-color);
    transition: all 300ms;
    &[data-answered] {
      --form-items-background-color: var(--fi-answered-background);
      --form-items-border-color: var(--fi-answered-border-color);
      --form-items-color: var(--fi-answered-text);
      border: 1px solid;
    }
    &[data-answered] {
      --form-items-background-color: var(--fi-answered-background);
      --form-items-border-color: var(--fi-answered-border-color);
      --form-items-color: var(--fi-answered-text);
      border: 1px solid;
    }
    > span[contenteditable] {
      vertical-align: text-top;
    }
    &:hover {
      --form-items-color: var(--text-color);
      --form-items-border-color: var(--text-color);
    }

    // Only apply the margin when not inside a paragraph which already have some set
    &:not(p .ql-form-item:only-child) {
      margin-top: 2px;
    }
  }

  ol,
  ul {
    list-style-type: none;
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;
    margin-top: 3px;

    & > li {
      list-style-type: none;
      margin-bottom: 2px;
      margin-left: 20px;

      &::before {
        display: inline-block;
        margin-left: -20px;
        margin-right: 6px;
        text-align: center;
        white-space: nowrap;
        width: 22px;
      }

      @include listLayouts();
    }
  }

  ul {
    & > li {
      &::before {
        content: "•";
        display: inline-block;
        margin-left: -28px;
        margin-right: 6px;
        text-align: center;
        font-family: var(--font-family);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        vertical-align: initial;
        white-space: nowrap;
      }

      @include ulContents();
    }
  }

  ol {
    counter-reset: list-0 list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;

    & > li {
      &::before {
        content: counter(list-0, decimal) ". ";
        counter-increment: list-0;
      }

      &:not([data-indent]) {
        &::before {
          content: counter(list-0, decimal) ". ";
        }
      }

      @include olContents();
    }
  }

  blockquote {
    position: relative;
    padding-left: 16px;
    margin: 4px 0;

    &::before {
      background: var(--surface-300);
      border-radius: 8px;
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      width: 4px;
    }
  }
}
