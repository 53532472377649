/*=============================================
=                     Tokens            =
-----------------------------------------------
Reusable CSS tokens file. Divided in 2 blocks:
- .root-container - variables that depends on customed colors such as primary-color or backgroundColor
- .root - dependency free variables. Contains: a responsive spacing system, color palette etc...  
=============================================*/

/**
 *
 * NB: Add declarations that depends on custom colors here.
 * Caused by the order of inheritance, so colors that depends on the primary colors WILL NOT BE APPLIED if defined inside the :root as they 
 * will be getting the hard coded default colors
 */
.root-container {
  --primary-color-hover: var(--primary-600);
  --background-hover-color: rgba(var(--primary-color-rgb), 0.08);

  /* Form Items Colors */
  --fi-border-radius: 0.2rem;

  --fi-default-background: #f8fafb;
  --fi-default-text: #4a6178;
  --fi-default-border-color: #b4c5d5;

  --fi-answered-background: #e1e8ee;
  --fi-answered-text: #4a6178;
  --fi-answered-border-color: #b4c5d5;

  /* Overwrite theme colors with custom colors */
  --primary-color-hover: var(--primary-400);

  /* Focus colors */
  --color-focus: var(--primary-400);
  --color-focus-shadow: var(--primary-100);

  /* Avatar colors */
  --avatar-background-color: var(--primary-500);
  --avatar-self-background: var(--avatar-background-color);

  --tabmenu-item-hover-color: var(--primary-color);
}

/**
 *
 * Add variables that do not depend on custom colors
 *
 */

:root {
  /* Borders and shadows */
  --surface-border: var(--surface-100);
  --surface-border-light: var(--bluegray-50);
  // Shadows (extracted from PrimeFlex)
  --shadow-1: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
  --shadow-2: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
  --shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); // used in theme.scss
  /**
    IMPORTANT: These styles will not be herited by some overlay components (menus, dialogs);
  */
  // https://uicolors.app/edit?sv1=blue:50-f5f9ff/100-ebf2ff/200-d6e6ff/300-b3cdff/400-85abff/500-527dff/600-2e58ff/700-143ceb/800-1233c4/900-112ba2;jumbo:50-fcfcfd/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  --blue-50: #f5f9ff;
  --blue-100: #ebf2ff;
  --blue-200: #d6e6ff;
  --blue-300: #b3cdff;
  --blue-400: #85abff;
  --blue-500: #527dff;
  --blue-600: #2e58ff;
  --blue-700: #143ceb;
  --blue-800: #1233c4;
  --blue-900: #112ba2;
  // https://uicolors.app/edit?sv1=nestor:50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // https://uicolors.app/edit?sv1=blue:50-f5f6ff/100-ebedff/200-d6dcff/300-b3baff/400-858dff/500-5252ff/600-372dff/700-2516eb/800-1f12c5/900-1b11a1;jumbo:50-fcfcfd/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // NESTOR
  --nestor-50: #f5f6ff;
  --nestor-100: #ebedff;
  --nestor-200: #d6dcff;
  --nestor-300: #b3baff;
  --nestor-400: #858dff;
  --nestor-500: #5252ff;
  --nestor-600: #372dff;
  --nestor-700: #2516eb;
  --nestor-800: #1f12c5;
  --nestor-900: #1b11a1;
  --nestor-color-rgb: 79, 70, 229;
  --nestor-primary: var(--nestor-500);
  // Primary Color
  --primary-50: var(--nestor-50);
  --primary-100: var(--nestor-100);
  --primary-200: var(--nestor-200);
  --primary-300: var(--nestor-300);
  --primary-400: var(--nestor-400);
  --primary-500: var(--nestor-500);
  --primary-600: var(--nestor-600);
  --primary-700: var(--nestor-700);
  --primary-800: var(--nestor-800);
  --primary-900: var(--nestor-900);

  // https://uicolors.app/edit?sv1=blue:50-f5f5ff/100-ecebff/200-d8d6ff/300-b8b3ff/400-9185ff/500-6e52ff/600-5b2eff/700-4614eb/800-3c12c4/900-3311a2;jumbo:50-fcfcfd/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // Indigo
  --indigo-50: #f5f5ff;
  --indigo-100: #ecebff;
  --indigo-200: #d8d6ff;
  --indigo-300: #b8b3ff;
  --indigo-400: #9185ff;
  --indigo-500: #6e52ff;
  --indigo-600: #5b2eff;
  --indigo-700: #4614eb;
  --indigo-800: #3c12c4;
  --indigo-900: #3311a2;

  // https://uicolors.app/edit?sv1=blue:50-f7f5ff/100-efebff/200-ded6ff/300-c4b3ff/400-a585ff/500-8b52ff/600-7e2eff/700-6a14eb/800-5912c4/900-4b11a2;jumbo:50-fcfcfd/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // Purple
  --purple-50: #f7f5ff;
  --purple-100: #efebff;
  --purple-200: #ded6ff;
  --purple-300: #c4b3ff;
  --purple-400: #a585ff;
  --purple-500: #8b52ff;
  --purple-600: #7e2eff;
  --purple-700: #6a14eb;
  --purple-800: #5912c4;
  --purple-900: #4b11a2;

  // https://uicolors.app/edit?sv1=blue:50-f5feff/100-ebfcff/200-d6faff/300-b3f4ff/400-85e9ff/500-52d4ff/600-2ec0ff/700-14a7eb/800-128cc4/900-1174a2;jumbo:50-fcfcfd/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // Cyan
  --cyan-50: #f5feff;
  --cyan-100: #ebfcff;
  --cyan-200: #d6faff;
  --cyan-300: #b3f4ff;
  --cyan-400: #85e9ff;
  --cyan-500: #52d4ff;
  --cyan-600: #2ec0ff;
  --cyan-700: #14a7eb;
  --cyan-800: #128cc4;
  --cyan-900: #1174a2;

  // https://uicolors.app/edit?sv1=blue:50-d4ffc7/100-bcffad/200-a3ff8a/300-76ff57/400-3cff1a/500-12d600/600-05a300/700-0a5e08/800-073d06/900-052a04;jumbo:50-f6f7f8/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // Green
  --green-50: #d5fbdd;
  --green-100: #bef9ca;
  --green-200: #a2f6b4;
  --green-300: #78f292;
  --green-400: #37ec5e;
  --green-500: #13c339;
  --green-600: #0b6f21;
  --green-700: #0a661e;
  --green-800: #074a16;
  --green-900: #053810;

  // https://uicolors.app/edit?sv1=blue:50-fff5f6/100-ffebed/200-ffd6dc/300-ffb3ba/400-ff8d85/500-ff5252/600-ff382e/700-eb2314/800-c41e12/900-a21b11;jumbo:50-f6f7f8/100-ebecef/200-d5d7dd/300-b0b4bf/400-84899a/500-6a6f81/600-5a5e6d/700-4e4f5f/800-454954/900-3f3e4c;nestor-(old):50-ebf0ff/100-d6e2ff/200-b8c7ff/300-8fa3ff/400-6673ff/500-4245ff/600-2e1fff/700-2715ef/800-1f14bd/900-1b157e
  // Red
  --red-50: #fff5f8;
  --red-100: #ffebf0;
  --red-200: #ffd6e2;
  --red-300: #ffb3c7;
  --red-400: #ff858d;
  --red-500: #ff5252;
  --red-600: #ff2e38;
  --red-700: #eb1423;
  --red-800: #c4121e;
  --red-900: #a2111b;

  --yellow-50: #fffbcf;
  --yellow-100: #fff8a5;
  --yellow-200: #fff47c;
  --yellow-300: #fdf053;
  --yellow-400: #f9ec29;
  --yellow-500: #f3e804;
  --yellow-600: #d3c500;
  --yellow-700: #b1a100;
  --yellow-800: #8a7d00;
  --yellow-900: #645900;

  --text-color-error: var(--red-600);
  --danger-color: var(--red-600);
  --warning-color: var(--orange-600);
  --success-color: var(--green-500);
  --info-color: var(--blue-600);
  --help-color: var(--purple-600);
  // Plan-based color tokens
  --upgrade-color: var(--yellow-300, #f6c050);
  --enterprise-color: #1b1680;
  --pro-color: #372dff;
  --starter-color: #ff001e;

  --primary-color-rgb: 79, 70, 229;
  --danger-color-rgb: 239, 68, 68;
  --danger-background-color: var(--red-100);
  --warning-color: var(--orange-500);
  /* var(--orange-500) */
  --warning-color-rgb: 249, 115, 22;
  --success-color-rgb: 34, 197, 94;
  --success-background-color: var(--green-100);
  // --info-color-rgb: 82, 125, 255;
  // --info-background-color: var(--primary-100);
  --help-color-rgb: 168, 85, 247;
  --secondary-color: var(--bluegray-500);
  --secondary-color-rgb: 100, 116, 139;

  --heading-font-family: "IBM Plex Sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;

  /*
      *This is a color palette generated from the Paprwork primary color 
      * https://www.tailwindshades.com/
    */
  // --primary-50: #ffedeb;
  // --primary-100: #ffdcd9;
  // --primary-200: #ffbcb5;
  // --primary-300: #ff9c91;
  // --primary-400: #ff7b6e;
  // --primary-500: #ff5b4a;
  // --primary-600: #ff2812;
  // --primary-700: #d91400;
  // --primary-800: #a10f00;
  // --primary-900: #690a00;
  // RGB values for the primary-500 color
  --primary-color-rgb: 66, 69, 255;
  --brand-background-color: #fcfcfe;
  --brand-background-color-rgb: rgb(252, 252, 254);

  // Mask color used as loader background
  --maskbg: rgba(0, 0, 0, 0.2);

  /* Avatar colors */
  --avatar-other-background: var(--surface-400);
  --avatar-text-color: var(--surface-50);
  --avatar-font-size: 12px;

  /* Messages Default Colors */
  --self-message-background-color: var(--surface-card);
  --self-message-border-color: var(--border-color);
  --other-message-background-color: var(--surface-card);
  --other-message-border-color: var(--border-color);

  --convenable-file-content-width: calc(var(--space-6xl-10xl, 10rem) - 2.5rem);

  /* Used as the maximun number of characters to ensure legibility (maximum width allowed)*/
  --large-measure: 100rem;
  --medium-measure: 80rem;
  --small-measure: 60rem;
  --xsmall-measure: 40rem;
  // https://css-tricks.com/six-tips-for-better-web-typography/#aa-rule-1-set-a-good-max-width-for-paragraphs
  --legible-measure: 75ch;

  --surface-header: white;
  --menu-width: 14.5rem;
  --header-height: 4rem;
  --text-color-tertiary: var(--bluegray-400);
  /**
     * Fluid spacing is a system for spacing that scales based on the size of the screen.
     * https://utopia.fyi/space/calculator?c=390,21,1.2,1140,24,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|4|4|6|20,xs-xl
     * clamp([minimum size], [viewport based computed value], [maximum size]) 
     */
  --space-3xs: clamp(0.31rem, calc(0.28rem + 0.13vw), 0.38rem);
  --space-2xs: clamp(0.69rem, calc(0.66rem + 0.13vw), 0.75rem);
  --space-xs: clamp(1rem, calc(0.94rem + 0.27vw), 1.13rem);
  --space-s: clamp(1.31rem, calc(1.22rem + 0.4vw), 1.5rem);
  --space-m: clamp(2rem, calc(1.87rem + 0.53vw), 2.25rem);
  --space-l: clamp(2.63rem, calc(2.43rem + 0.8vw), 3rem);
  --space-xl: clamp(3.94rem, calc(3.65rem + 1.2vw), 4.5rem);
  --space-2xl: clamp(5.25rem, calc(4.86rem + 1.6vw), 6rem);
  --space-3xl: clamp(6.56rem, calc(6.08rem + 2vw), 7.5rem);
  --space-4xl: clamp(7.88rem, calc(7.29rem + 2.4vw), 9rem);
  --space-5xl: clamp(9.19rem, calc(8.51rem + 2.8vw), 10.5rem);
  /* Custom pairs (from one size to another one) */
  --space-2xs-m: clamp(0.69rem, calc(-0.12rem + 3.33vw), 2.25rem);
  --space-xs-xl: clamp(1rem, calc(-0.82rem + 7.47vw), 4.5rem);
  --space-xs-4xl: clamp(1rem, calc(-1.6rem + 10.67vw), 6rem);
  --space-xs-19xl: clamp(1rem, calc(-14.08rem + 61.87vw), 30rem);
  --space-6xl-10xl: clamp(9.19rem, calc(6.17rem + 12.4vw), 15rem);
  --space-7xl-13xl: clamp(11.81rem, calc(6.26rem + 22.8vw), 22.5rem);
}
