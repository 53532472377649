/*=============================================
=     Utilies include single declarations      =
=============================================*/
.pre-line {
  white-space: pre-line;
}
/* Visually hide an element & remains vsible for screen reader */
.sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px !important;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px !important;
}

/*
=============================================
=            TYPOGRAPHY                     =
=============================================
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font-family);
  font-weight: 600;
  line-height: 1.2;
}

.h1,
.h2,
.h3,
.h4 {
  font-family: var(--heading-font-family);
  font-weight: 600;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2rem;
}

h2,
.h2 {
  font-size: 1.5rem;
}

h3,
.h3 {
  font-size: 1.25rem;
}

h4,
.h4 {
  font-size: 1.125rem;
}

.clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  &.lines-2 {
    -webkit-line-clamp: 2;
  }
  &.lines-3 {
    -webkit-line-clamp: 3;
  }
}

.surface-brand {
  background-color: var(--brand-background-color);
}
.surface-c {
  background-color: var(--surface-c);
}
.surface-border-light {
  border-color: var(--surface-border-light) !important;
}

.surface-border-color {
  border-color: var(--border-color);
}
.hover\:surface-hover:hover {
  background-color: var(--surface-hover);
}

.text-color-tertiary {
  color: var(--text-color-tertiary);
}
.text-secondary {
  color: var(--secondary-color);
}

.label {
  font-weight: 500;
}

.scrollable {
  max-height: var(--scrollable-max-height, 14rem);
  overflow-y: auto;
  &.shadowed {
    // https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/
    background:
    /* Shadow Cover TOP */ linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
      /* Shadow Cover BOTTOM */ linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
      /* Shadow TOP */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center top,
      /* Shadow BOTTOM */ radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom;
    background-repeat: no-repeat;
    background-size: 100% 2.5rem, 100% 2.5rem, 100% 1rem, 100% 1rem;
    background-attachment: local, local, scroll, scroll;
  }
}

.visibility-hidden {
  visibility: hidden;
}

/* Applies a full width background that goes beyond the width of the element
- useful to avoid wrapping an element inside a parent div just to style the background
more: https://css-tricks.com/full-bleed/
*/
.full-bleed {
  --full-bleed-background: var(--surface-100);
  border-image: linear-gradient(var(--full-bleed-background), var(--full-bleed-background)) fill 1/0/0 50vw;
}

.max-w-legible {
  max-width: var(--legible-measure);
}
/*
Used to disable hints that invite users to click
   
*/
.no-interactive {
  cursor: default;
}
.no-interactive:focus,
.no-interactive:enabled:active,
.no-interactive:enabled:hover {
  --color-focus-shadow: transparent;
  --color-focus: transparent;
  background: transparent !important;
  box-shadow: none;
  outline: none;
  outline-offset: 0;
}

.underline-dotted {
  text-decoration: underline dotted var(--underline-color, var(--text-color-secondary));
  text-underline-offset: 0.2rem;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.no-shadow {
  box-shadow: none !important;
}

/**
https://github.com/primefaces/primeng/issues/13108
Workaround to duplicated icons in primeNg < 16.2
Use this class to hide one icon
*/
.hide-duplicated-icon {
  i.pi {
    display: none;
  }
}

.p-button-nestor {
  --primary-color: var(--nestor-500);
  --primary-color-hover: var(--nestor-600);
  --primary-color-rgb: var(--nestor-color-rgb);

  --color-focus-shadow: var(--nestor-100);
  --color-focus: var(--nestor-400);
}

.ungrow-button-label .p-button-label {
  flex-grow: 0 !important;
}

.custom-button-set {
  > *:not(:first-child) {
    border-left: 1px solid var(--custom-button-set-border-color, var(--border-color)) !important;
  }
}
