@import "tokens.scss";
@import "mixins.scss";
@import "editor.scss";
@import "layouts.scss";
@import "utilities.scss";
@import "icons.scss";
@import "providers.scss";

// Plugins
@import "plugins/intl-tel-input.scss";
@import "plugins/quill.scss";
@import "plugins/tooltips.scss";
@import "plugins/flags.scss";
@import "plugins/ngx-otp-input.scss";
@import "plugins/emoji.scss";

/*=============================================
=       Global styles     =
=============================================*/
html,
body {
  height: 100%;
  font-size: 14px;
  line-height: 1.5;
  margin: 0px;
  font-family: var(--font-family);
}

canvas,
img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

.p-avatar-group .p-avatar {
  border: 2px solid var(--grouped-avatar-border-color, #ffffff);
}
/* Default background set to primary color with white text */
p-avatar {
  --background-active-color: var(--avatar-background-color);
  color: var(--avatar-text-color);
  display: flex; // Fix : Breaking avatar group layout with images instead of initials

  .p-avatar {
    font-size: var(--avatar-font-size);
    img {
      background-color: var(--surface-overlay);
      border-radius: var(--image-border-radius, 2px);
    }
  }

  &.inactive {
    --background-active-color: var(--surface-400) !important;
    color: var(--surface-0);
  }

  &.declined {
    --background-active-color: var(--red-400) !important;
    color: var(--surface-0);
  }
}
/* Remove primary color from input borders as it ressembles the error color */
.p-inputtext:enabled:focus,
.phone-input:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--color-focus);
}
/* Custom focus styles - add where needed with `data-focus` */
.p-inputtext:enabled:focus,
.p-button:focus,
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus,
.phone-input:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus:focus-visible,
[data-focus]:focus {
  box-shadow: 0 0 0 0.2rem var(--color-focus-shadow);
  outline: 1px solid var(--color-focus);
  outline-offset: -1px;
  // border-color: var(--color-focus);
}
.phone-input {
  width: 100%;
}
.country-input {
  width: 100%;
}

// Have to override style : https://github.com/webcat12345/ngx-intl-tel-input/issues/178
ngx-intl-tel-input {
  &.ng-invalid.ng-dirty {
    .phone-input {
      border: 1px solid var(--color-invalid);
    }
  }
}

// Wrap the content of the form inside a div with this class
/**
----
<p-dialog styleClass="dialog-nested">
  <my-component-with-nested-footer/>
</p-dialog>
----
* MY COMPONENT WITH NESTED FOOTER
<form>
  <main class="dialog-nested-content"></main>
  <footer class="dialog-nested-footer"></footer>
</form>
 */

.p-dialog {
  --dialog-padding: 1.5rem;
  --dialog-footer-padding: var(--dialog-padding);
  --dialog-footer-border-top: 1px solid var(--surface-border);
  --dialog-content-radius: 0;
  --dialog-content-padding: var(--dialog-padding);
  --dialog-content-background: #f8fafc;

  overflow: var(--dialog-overflow, visible);

  &.dialog-nested {
    &.dialog-header-hidden {
      .p-dialog-header {
        display: none;
      }
    }
    --dialog-content-padding: 0;
    // --dialog-content-overflow-y: auto; // prevents overlay elments (eg: Date picker) from being enpreisoned inside the modal
    overflow: hidden;
    .dialog-nested-content {
      padding: var(--dialog-padding) var(--dialog-padding) var(--dialog-padding) var(--dialog-padding);
    }
    // Wrap the form's buttons inside a footer tag to emulate a dialog footer
    .dialog-nested-footer {
      padding: var(--dialog-padding);
      position: sticky;
      bottom: 0;
      width: 100%;
      background-color: var(--surface-card);
      border-top: 1px solid var(--surface-border);
      border-bottom-right-radius: var(--dialog-footer-radius, var(--border-radius));
      border-bottom-left-radius: var(--dialog-footer-radius, var(--border-radius));
      margin-top: auto;
    }
  }
  .p-dialog-header {
    background: var(--dialog-header-background, var(--surface-header));
    padding: var(--dialog-header-padding, var(--dialog-padding, 1.5rem));
    border-bottom: var(
      --dialog-header-border-bottom,
      1px solid var(--dialog-header-border-color, var(--surface-border))
    );
    .p-dialog-title {
      font-size: 1.25rem;
    }
  }
  .p-dialog-content {
    background: var(--dialog-content-background, var(--surface-overlay));
    padding: var(
      --dialog-content-padding,
      0 var(--dialog-padding, 1.5rem) var(--dialog-padding, 1.5rem) var(--dialog-padding, 1.5rem)
    );
    border-bottom-right-radius: var(--dialog-content-radius, var(--border-radius));
    border-bottom-left-radius: var(--dialog-content-radius, var(--border-radius));
    overflow-y: var(--dialog-content-overflow-y, auto);
  }
  .p-dialog-footer {
    padding: var(--dialog-footer-padding, 0 1.5rem 1.5rem 1.5rem);
    border-top: var(--dialog-footer-border-top, 0 none);
  }
  &.p-confirm-dialog {
    --dialog-footer-padding: var(--dialog-padding);
    --dialog-footer-border-top: 1px solid var(--surface-border);
    --dialog-content-radius: 0;
    --dialog-content-padding: var(--dialog-padding);
    span.p-confirm-dialog-message {
      margin-left: 0 !important;
      line-height: 1.5;
    }
  }
}
/* Tokenize Table for further styling */
.p-datatable {
  --maskbg: rgba(0, 0, 0, 0.04);
  --table-header-background: var(--surface-50);
  :has(.p-datatable-loading-overlay) {
    --table-border-color: transparent !important;
  }
  .p-datatable-loading-overlay {
    border-radius: var(--border-radius);
    min-height: 5rem;
  }
  &.p-component {
    box-shadow: var(--table-shadow, none);
    overflow: var(--table-overflow, visible);
    // border: var(--table-border-width, 0px) solid var(--table-border-color, var(--surface-border));
  }
  .p-datatable-wrapper {
    border: var(--table-border-width, 0px) solid var(--table-border-color, var(--surface-border));
    border-radius: var(--table-border-radius, 0);
    box-shadow: var(--table-wrapper-shadow, none);
  }
  .p-datatable-header {
    padding: var(--table-header-padding, 1.25rem 1.25rem) !important;
  }
  .p-datatable-thead {
    border-width: var(--table-header-border-width, 0 0 1px 0);
    > tr > th {
      color: var(--text-color-secondary);
      background-color: var(--table-header-background, var(--surface-header));
      border-width: var(--table-header-border-width, 0 0 1px 0);
      border-color: var(--table-border-color);
    }
  }
  .p-datatable-tbody > tr:not(:last-of-type) > td {
    border-color: var(--table-body-border-color, var(--surface-border));
  }
  .p-paginator-bottom {
    --text-highlight-color: var(--primary-color);
    --highlight-background-color: rgba(var(--primary-color-rgb), 0.15);
    border-width: var(--table-bottom-border-width, 1px);
    border-color: var(--table-border-color, var(--surface-border));
    border-radius: var(--table-bottom-border-radius, var(--border-radius));
    justify-content: var(--table-bottom-justify-content, flex-end);
    padding: var(--table-bottom-padding, 0.75rem 1.5rem);
    .p-paginator-current {
      width: var(--paginator-current-width);
    }
  }
}
@media screen and (max-width: 769px) {
  .p-paginator-bottom {
    --table-bottom-padding: 0.75rem 0;
    --table-bottom-justify-content: center;
    --paginator-current-width: 100%;
  }
}

.p-datepicker-touch-ui,
.p-calendar .p-datepicker-touch-ui {
  min-width: auto !important;
  width: calc(100% - var(--fluid-padding) * 2) !important;
  max-width: var(--small-measure, 100rem) !important;
}
.field {
  margin-bottom: 1.5rem;
}
.field label {
  font-weight: 500;
}
.field-invalid {
  --border-color: var(--color-invalid);
}
/* ANIMATIONS & TRANSITIONS */
.p-inputtext,
.p-button {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

/* Gray disabled buttons  */
.p-disabled,
.p-component:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: var(--color-disabled, var(--text-color));
  background-color: var(--background-disabled, var(--surface-200));
  border-color: var(--background-disabled, var(--surface-200));
}
.pagination-button {
  --color-disabled: var(--text-color-secondary);
  --background-disabled: transparent;
}

/* Tieredmenu */
.p-tieredmenu {
  width: 100%;
  max-width: 17.5rem;
}

/*=============================================
=                Custom Components     =
=============================================*/
/* Postcard
- Cells combines one label and its contents  */
.postcard-container {
  --postcard-blue: #3039f5;
  --postcard-red: #eb332f;
  --postcard-white: #ffffff;
  --postcard-text-color: var(--surface-900, #000);
  --postcard-text-color-secondary: var(--surface-700, #020202);

  --strip-width: 20px;
  // box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.25);
  box-shadow: var(--shadow-1);
  background: repeating-linear-gradient(
    135deg,
    var(--postcard-blue),
    var(--postcard-blue) calc(var(--strip-width) * 1),
    var(--postcard-white) calc(var(--strip-width) * 1),
    var(--postcard-white) calc(var(--strip-width) * 2),
    var(--postcard-red) calc(var(--strip-width) * 2),
    var(--postcard-red) calc(var(--strip-width) * 3),
    var(--postcard-white) calc(var(--strip-width) * 3),
    var(--postcard-white) calc(var(--strip-width) * 4)
  );
  padding: 8px;
  border-radius: 0.5rem;
  max-width: var(--measure, 100ch);
  .postcard {
    --cell-gutter: var(--space-3xs);
    --postcard-gutter: var(--space-s);
    --postcard-padding: var(--space-m, 1.5rem) var(--space-2xs-m, 0.5rem);
    display: grid;
    grid-template-columns: 1fr;
    background-color: var(--postcard-white, #fff);
    color: var(--postcard-text-color);
    border-radius: inherit;
    padding: var(--postcard-padding);
    gap: var(--postcard-gutter, 1rem);
    &__header {
      gap: var(--postcard-gutter, 1rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap-reverse;
    }
    &__topic {
      font-weight: 600;
    }
    &__cell {
      /* Always use .postcard__cell with .stack layout */
      --stack-space: var(--postcard-gutter, 0.125rem);
      &__label {
        font-weight: 500;
      }
      &__content {
        color: var(--postcard-text-color-secondary, var(--surface-700, #020202));
        --stack-space: var(--cell-gutter, 0.125rem);

        &--html {
          @include messageHtmlStyles();
          color: var(--text-color);
          font-size: 0.875rem;
          position: relative;
          padding-left: 24px;
          margin: 8px 0 0 0;
        }
      }
    }
  }
}
/* Recipients Input Chips  */
.recipients-custom-chips {
  .p-chips {
    width: 100%;
  }
  .p-chips-token {
    padding-right: 0.75rem !important;
    padding-left: 0 !important;
    border: 1px solid transparent;
    .recipients-custom-chips-text {
      line-height: 1;
      margin-left: 0.5rem;
    }
    &.data-chip-invalid {
      border: 1px dashed var(--red-500);
      background-color: var(--red-50);
      .p-chips-token-icon {
        color: var(--red-500);
      }
    }
  }
  .p-chips-multiple-container {
    gap: 0.5rem 0rem;
    width: 100%;
  }
}
/*     Format List
----------------------------------------------- */
.p-listbox {
  border: none !important;
}
/*     Switch Field    
-----------------------------------------------
Clickable checkbox field built on prime NG's,
Usage:
<label class="switch-field">
  <div class="field-text">
    <span class="field-text__title">...</span>
    <span class="field-text__subtitle>...</span>
  </div>
  <p-checkbox></p-checkbox>
</label>
-----------------------------------------------*/
label.switch-field {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--space-xs, 8px);
  padding-bottom: var(--space-xs, 8px);
  gap: var(--space-2xs, 4px);
  margin-bottom: 0;
  .field-text {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--space-3xs, 8px);
    /* Prevent text selection  */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    &__title {
      font-weight: 500;
    }
    &__subtitle {
      color: var(--text-color-secondary);
      font-size: 0.875rem;
    }
  }
}

/*=============================================
=   Modifiers - applies where specified      =
=============================================*/

/* Use to for forcibly hide button labels */
[data-button~="noLabel"] {
  &.p-button-icon-only::after {
    content: "";
  }
  .p-button-label {
    display: none !important;
  }
}
[data-button~="noFocus"] {
  --color-focus: transparent;
  --color-focus-shadow: transparent;
}
[data-paginator="hide"] .p-paginator {
  display: none;
}

/* Date based timeline */
[data-timeline~="lifecycle"] {
  .p-timeline-horizontal {
    --connector-color: var(--avatar-background-color);
    .p-timeline-event-separator {
      .p-timeline-event-connector {
        height: 2px;
        background: linear-gradient(
          to right,
          var(--dash-color, var(--connector-color)) 50%,
          var(--connector-color) 50%
        );
        background-size: 16px 2px, 100% 2px;
      }
      [data-marker="dashed"] ~ .p-timeline-event-connector {
        --dash-color: transparent;
        --connector-color: var(--background-active-color);
      }
    }
  }
}

/* Styles the timeline component used as a stepper  */
[data-timeline~="truncated"] {
  .p-timeline-event-opposite,
  .p-timeline-event-content {
    max-width: calc(var(--available-space, 50%) - 1rem);
  }
  .p-timeline-event-opposite *,
  .p-timeline-event-content * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
[data-timeline~="opposite:none"] {
  --available-space: 100%;
  .p-timeline-event-opposite {
    flex: 0;
    padding: 0 !important;
  }
}
/** Record Tags Input*/
.tags-custom-chips {
  .p-chips-token {
    --border-radius: 1rem;
    padding: 0.175rem 0.75rem !important;
    line-height: 1.3;
    border: 1px solid transparent;
  }
}

.user-menu {
  width: auto;
  max-width: 300px;
}

.tags-panel {
  max-width: 400px;
  width: 100%;
  border: 1px solid var(--border-color);
  margin-top: -3.8rem !important; //
  --badge-background-color: rgba(var(--primary-color-rgb), 0.02);
  --badge-color: var(--primary-500);
  --badge-border-color: var(--primary-500);
  &::before,
  &::after {
    display: none;
  }
  .p-overlaypanel-close {
    --primary-color-hover: var(--surface-100);
    border-radius: var(--border-radius);
    background-color: white;
    color: var(--text-color);
    border: 1px solid var(--text-color);
    width: 10ch;
    height: 1.5rem;
    top: -0.75rem;
    right: -2ch;
    font-size: 0.75rem;
    display: flex;
    padding: 0.25rem 0.5rem;
    &:enabled:hover {
      color: var(--text-color);
    }
    &::after {
      content: var(--p-overlaypanel-close-label);
      margin-left: 0.25rem;
      font-weight: 600;
    }
    .p-overlaypanel-close-icon {
      font-size: 0.75rem;
    }
  }
  .p-overlaypanel-content {
    padding: 0;
  }
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &__main {
    padding: 0.75rem;
  }
  .p-listbox {
    .p-listbox-empty-message {
      display: none;
    }
    .p-listbox-header {
      padding: 0;
    }
    .p-listbox-list-wrapper .p-listbox-list {
      padding: 0 !important;
      display: flex;
      flex-wrap: wrap;
      .p-listbox-item {
        padding: 0 0.25rem 0 0;
        --background-hover-color: transparent;
        border-radius: 7rem 10rem 10rem 7.5rem;
      }
    }
    .p-chips {
      width: 100%;
      --color-focus-shadow: var(--primary-100);
      --color-focus: var(--primary-color);
    }
    .p-chips-input-token {
      input {
        font-size: 0.8rem;
      }
    }
    .p-chips-token {
      font-size: 0.8rem;
      padding: 0.3rem 0.55rem;
      background-color: var(--badge-background-color, var(--surface-600));
      color: var(--badge-color, #fff);
      border: 1px var(--badge-border-style, solid) var(--badge-border-color, transparent);
      border-radius: 2rem;
    }
    .p-chips-multiple-container {
      gap: 0.5rem 0rem;
      width: 100%;
    }
  }
}

.unit-menu {
  --menu-width: 100%;
  width: 100%;
  max-width: var(--menu-width);
  border: 1px solid var(--surface-border);
  box-shadow: var(--shadow);
  margin-top: 3px !important;
  @media screen and (min-width: 30em) {
    --menu-width: 28rem;
  }
  &::before,
  &::after {
    display: none;
  }

  .p-overlaypanel-content {
    padding: 0 0 0.85rem 0;
  }
  .units-menu__list {
    display: grid;
    padding: 0 0.75rem 0.85rem 0.75rem;
    overflow: auto;
    overscroll-behavior: contain;
    max-height: min(620px, 100vh - var(--header-height, 10rem));
    .unit-category {
      text-transform: uppercase;
      color: var(--text-color-secondary);
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      padding: 16px 0.75rem 8px 0;
      grid-template-columns: 1fr auto;
      span {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 16px;
      }
    }

    .unit-card {
      --menu-item-image-size: 2.3rem;
      --image-border-radius: 50%;
      display: grid;
      align-items: center;
      border-radius: var(--border-radius);
      grid-template-columns: var(--menu-item-image-size) 1fr auto 2rem;
      grid-template-rows: var(--menu-item-image-size);
      grid-template-areas: "unit__image unit__name unit__badge unit__options";
      gap: 0.5rem;
      text-align: left;
      width: 100%;
      padding: 0.875rem;
      margin: 4px 0;
      border: 1px solid var(--unit-card-border-color, var(--surface-border));
      cursor: pointer;
      &:hover {
        background-color: rgba(var(--primary-color-rgb), 0.04);
        --unit-card-border-color: var(--primary-color);
      }
      &.isActive:not(:hover) {
        background-color: rgba(var(--primary-color-rgb), 0.04);
      }
      .unit__image {
        grid-area: unit__image;
        display: grid;
        height: 100%;
        > img {
          border-radius: var(--image-border-radius, 2px);
        }
        > span {
          display: grid;
          place-content: center;
          background-color: var(--surface-100);
          color: var(--text-color);
          font-weight: 700;
          border-radius: var(--image-border-radius, 2px);
        }
      }
      .unit__name {
        grid-area: unit__name;
        color: var(--text-color);
        font-weight: 600;
        line-height: 1.5;
        // font-size: 0.875rem;
      }
      .unit__badge {
        grid-area: unit__badge;
        font-size: 0.85rem;
        padding: 0.25rem 0.625rem;
        color: var(--badge-color, var(--bluegray-500));
        background-color: var(--badge-background-color, var(--bluegray-50));
        border: 1px solid var(--badge-border-color, var(--bluegray-100));
        border-radius: 32px;
        line-height: 1.2;
        &.free {
          --badge-color: var(--green-500);
          --badge-border-color: var(--green-100);
          --badge-background-color: var(--green-50);
        }
      }
      .unit__options {
        grid-area: unit__options;
        button:hover {
          --border-radius: 4px;
          background-color: rgba(var(--secondary-color-rgb), 0.08);
        }
      }
    }
  }
  .units-menu__footer {
    padding: 1.15rem 0.85rem 0.6rem 0.85rem;
    border-top: 1px solid var(--surface-border);
  }
}

.message,
.message-preview {
  .message__body__html {
    overflow-y: auto;
    @include messageHtmlStyles();
  }

  &.message--parent {
    .message__body__html {
      p {
        margin: 0 !important;
      }
    }
  }
}

.form-answer {
  .form-answer__message {
    .form-answer__message__html {
      @include messageHtmlStyles();
    }
  }
}

/* Tertiary button */
.p-button-tertiary,
.p-splitbutton-tertiary button {
  color: var(--primary-color);
  background: transparent;
  border-color: transparent;
  background-color: rgba(var(--primary-color-rgb, 255, 255, 255), 0.05);

  &:enabled {
    &:hover,
    &:active,
    &:focus {
      color: var(--primary-color);
      background-color: rgba(var(--primary-color-rgb, 255, 255, 255), 0.1);
    }
  }
}

/* Button link danger */
.p-button-link {
  &.p-button-danger {
    color: var(--danger-color);
    border-color: transparent;
    background: transparent;

    &:enabled:hover {
      color: var(--red-700);
      border-color: transparent;
      background: transparent;
    }
  }
}

label {
  &.p-button {
    &:hover,
    &:active {
      background: var(--primary-color-hover);
      color: #ffffff;
      border-color: var(--primary-color-hover);
    }
  }
  &.p-button.p-button-secondary {
    --primary-color: var(--secondary-color);
    --primary-color-rgb: var(--secondary-color-rgb);
  }
  &.p-button.p-button-outlined {
    &:hover,
    &:active {
      background: rgba(var(--primary-color-rgb), 0.04);
      color: var(--primary-color);
      border: 1px solid;
    }
  }
}

[contenteditable] {
  -webkit-tap-highlight-color: transparent;
  transition: background 0.2s ease 0s;

  &:focus {
    outline: 0;
  }

  &:empty,
  &.force-placeholder {
    &::after {
      content: attr(placeholder);
      color: var(--text-color-secondary);
    }
  }

  // &:hover {
  //   background: var(--surface-100);
  // }
}

.button-hover {
  transition: 0.4s;
  overflow: hidden;

  .p-button-label {
    max-width: 0;
    opacity: 0;
    white-space: nowrap;
    transition: 0.4s ease-in-out;
  }

  .p-button-icon-left {
    margin-right: 0;
  }

  &.button-hover--hovered {
    .p-button-label {
      max-width: 300px;
      margin-left: 0.5rem;
      opacity: 1;
    }
  }
}
// Moves crisp out of the way on smaller devices
@media screen and (max-width: 769px) {
  #crisp-chatbox {
    transform: translateY(-4rem);
  }
}
#crisp-chatbox {
  z-index: 1000 !important;
}
/*
Variant used to fix the animation being added to an element that do not have inherent width and height
so we change the display property for the duration of the animation
e.g: <record-form-item-answer>
*/
.fade-background-block {
  display: block;
}
.fade-background,
.fade-background-block {
  --fade-origin-opacity: 0.2;
  --fade-color: rgba(var(--secondary-color-rgb), var(--fade-origin-opacity));
  background-color: var(--fade-color);
  box-shadow: 0 0 0 1em var(--fade-color);
  border-radius: var(--border-radius);
  animation: fade-background-animation 2s 1 ease-in;
}
@keyframes fade-background-animation {
  0%,
  60% {
    background-color: rgba(var(--secondary-color-rgb), var(--fade-origin-opacity));
    box-shadow: 0 0 0 1em rgba(var(--secondary-color-rgb), var(--fade-origin-opacity));
  }
  100% {
    background-color: rgba(var(--secondary-color-rgb), 0);
    box-shadow: 0 0 0 1em rgba(var(--secondary-color-rgb), 0);
  }
}

@media screen and (max-width: 769px) {
  .message {
    --message-actions-visibility: visible;
    --message-actions-opacity: 1;
  }
}
.message {
  --left-pointing-curve: var(--border-radius) var(--border-radius) var(--border-radius) 0rem;
  --all-side-curve: var(--border-radius);
  --right-pointing-curve: var(--border-radius) var(--border-radius) 0rem var(--border-radius);
  --default-curve: var(--border-radius);
  --default-content-width: 75%;
  --default-parent-message-offset: calc(34px + 0.5rem);
  --message-body-max-width: 100%;
  padding-right: var(--message-padding-right);
  padding-left: var(--message-padding-left);
  flex-direction: var(--message-direction);
  align-items: var(--message-align, end);

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    // flex-basis: var(--message-content-flex-basis, var(--default-content-width));
    max-width: var(--message-content-max-width, var(--default-content-width));
    cursor: var(--message-content-cursor);
    position: relative;

    .message-broadcasted__message {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
      align-items: center;
      color: var(--text-color-tertiary);
      font-size: 0.875rem;

      i {
        font-size: 1.25rem;
      }
    }

    .message__body {
      border: 1px solid;
      display: flex;
      flex-direction: column;
      gap: var(--message-body-gap, 0.5rem);
      align-self: var(--message-body-align, start);
      border-radius: var(--message-body-curve, var(--all-side-curve));
      background-color: var(--message-body-background-color);
      border-color: var(--message-body-border-color);
      padding: var(--message-body-padding-y, 0.875rem) var(--message-body-padding-x, 0.875rem);
      max-width: var(--message-body-max-width);
      // word-break: break-all; Remove because splits words at random
    }

    // Attachments are displayed as a grid
    .auto-grid {
      --space: var(--cell-gutter, 0.125rem);
      --grid-placement: auto-fit;
      & > *:only-child {
        margin-left: var(--grid-singleton-left-margin);
        margin-right: var(--grid-singleton-right-margin);
      }
    }
  }
  &__header {
    .message__metadata {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.5rem;
      font-size: 0.875rem;
      color: var(--text-color-secondary);
      justify-content: var(--message-metadata-justify);
    }
  }
  &__actions {
    display: flex;
    flex-direction: var(--message-actions-direction, row-reverse);
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 1lh; // offset added to create a visual alignment with the message box
    transition: visibility 0s, opacity 0.2s ease-in-out;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  // Modifiers
  &--inactive {
    --message-body-background-color: var(--surface-50);
    --message-body-border-color: var(--surface-400);
  }
  &[data-message-sender="bot"] {
    --message-body-background-color: var(--other-message-background-color);
    --message-body-border-color: var(--other-message-border-color);
  }
  &[data-message-sender="self"] {
    /* Avatar and name to the right */
    --message-direction: row-reverse;
    --message-body-align: flex-end;
    --message-body-background-color: var(--self-message-background-color);
    --message-body-border-color: var(--self-message-border-color);
    --message-metadata-justify: flex-end;
    --grid-singleton-left-margin: auto;
    --message-actions-direction: row;

    p-avatar {
      --background-active-color: var(--avatar-self-background);
    }

    .message__content {
      .message__broadcasted-icon {
        left: unset;
        right: -12px;
      }
    }
  }
  &[data-message-sender="other"] {
    --message-body-background-color: var(--other-message-background-color);
    --message-body-border-color: var(--other-message-border-color);
    --grid-singleton-right-margin: auto;

    p-avatar {
      --background-active-color: var(--avatar-other-background);
    }
  }
  &[data-parent-message-sender="self"] {
    --message-body-curve: var(--parent-curve);
    --message-padding-right: var(--default-parent-message-offset);
    --message-direction: row-reverse;
  }
  &[data-parent-message-sender="other"] {
    --message-body-curve: var(--parent-curve);
    --message-padding-left: var(--default-parent-message-offset);
  }
  &.message--parent {
    display: flex;
    align-items: flex-end;
    margin-bottom: -1px;
    font-size: 0.75rem;
    --message-content-cursor: pointer;
    --message-body-background-color: var(--surface-c);
    --message-body-curve: var(--parent-curve);
    --message-body-padding-y: 0.4rem;
    --message-content-max-width: var(--default-content-width);
    --message-body-gap: 0;
    .message__content .message__body {
      min-width: 8rem;
      border-width: 1px 1px 1px 4px;
      border-color: var(--self-message-border-color);
      .ql-form-item {
        pointer-events: none;
      }
    }
  }
}

/**
 * Payment
 */
ngx-stripe-card {
  width: 100%;
}

.payment-method--card {
  margin: 0;
}
//
.p-avatar-group ui-recipient-avatar + ui-recipient-avatar {
  margin-left: -1rem;
}

.p-inplace .p-inplace-display {
  display: inline-flex !important;
  padding: 0.75rem 1rem !important;
}

// CUSTOM TABS
@media screen and (max-width: 769px) {
  .p-tabmenu.responsive-stack {
    padding-bottom: 2px;
    padding-top: 2px;
    .p-tabmenu-nav {
      flex-direction: column;
      gap: 0.25rem;
      padding: 2px;
      border: none;
      .p-tabmenuitem {
        .p-menuitem-link {
          border-color: var(--surface-border);
          border-width: 1px;
          margin-top: 2px;
          border-radius: var(--border-radius);
        }
      }
    }
  }
  .p-fieldset .p-fieldset-content {
    padding: 0.125rem;
  }
  // Used to fix a bug on iOS 14 where material icons take up more space than needed
  // hence squeezing adjacent elements (see NP-417 attachment).
  .material-symbols-rounded {
    max-width: 2em;
  }
}

.templates-table {
  --tooltip-background-color: rgba(255, 255, 255, var(--t-alpha, 1));
  --tooltip-color: var(--text-color);
  --tooltip-width: var(--space-7xl-13xl, 10rem);
  --tooltip-height: var(--space-6xl-10xl, 10rem);
  .p-datatable {
    position: initial !important;
  }
  .p-tooltip {
    max-width: var(--tooltip-width);
    filter: drop-shadow(0 0 0.125rem rgba(0, 0, 0, 0.2));
    left: unset !important;
    .p-tooltip-text {
      max-height: var(--tooltip-height);
      overflow: hidden;
      box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgb(0 0 0 / 10%), 0 -4px 6px -2px rgb(0 0 0 / 5%);
      & > :first-child {
        margin-top: 0;
      }
      & > :last-child {
        margin-bottom: 0;
      }
      @include messageHtmlStyles();
      .ql-form-item {
        pointer-events: none;
      }
    }
  }
}
.templates-preview-panel {
  // --tooltip-width: var(--space-7xl-13xl, 10rem);
  --preview-height: var(--space-6xl-10xl, 10rem);
  max-width: calc(100vw - var(--space-s));
  // max-height
  filter: drop-shadow(0 0 0.125rem rgba(0, 0, 0, 0.2));
  // left: unset !important;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgb(0 0 0 / 10%), 0 -4px 6px -2px rgb(0 0 0 / 5%);
  &::after {
    left: var(--overlayArrowLeft);
  }
  &::before {
    left: var(--overlayArrowLeft);
  }
  .p-overlaypanel-content {
    padding: 0.5rem;
    max-height: var(--preview-height);
    max-width: var(--small-measure, 20rem);
    min-width: 4rem;
    overflow: auto;
    div {
      // Remove extra margins
      & > :first-child {
        margin-top: 0;
      }
      & > :last-child {
        margin-bottom: 0;
      }
      @include messageHtmlStyles();
      .ql-form-item {
        pointer-events: none;
      }
    }
  }
}
.p-tag-value {
  // Helps define the height only with padding
  line-height: 100%;
}
.signature-checkbox {
  --p-checkbox-width: 2rem;
  --p-checkbox-height: 2rem;
  margin-left: auto;
}

.header-new-record-menu {
  --menu-width: 100%;
  --menu-item-image-height: 3rem;
  --menu-item-image-size: calc(var(--menu-item-image-height) + 1rem);
  --menu-tem-image-alignment: baseline; //Align image to the top
  --dropdown-padding: 1rem;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-1);
  padding: var(--dropdown-padding);
  @media screen and (min-width: 40em) {
    --menu-width: 35em;
    --menu-item-image-height: 4rem;
    --menu-tem-image-alignment: auto;
  }
  .p-menuitem + .p-menuitem > .p-menuitem-link {
    margin-top: var(--dropdown-padding);
  }
  .p-menuitem-link {
    --background-hover-color: rgba(var(--primary-color-rgb), 0.03);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
      0px 1px 4px rgba(var(--primary-color-rgb), 0.08);
    border-radius: var(--border-radius);
    background-color: var(--surface-card);
    border: 1px solid;
    border-color: transparent;
    transition: ease-in var(--transition-duration);
    &:not(.p-disabled):hover {
      border-color: var(--primary-400);
      .pi {
        color: var(--primary-color);
      }
    }

    .menu-item {
      display: grid;
      grid-template-rows: auto 1fr auto;
      grid-template-columns: 1fr 1fr auto;
      grid-template-areas: "image image image" "title title title" "subtitle subtitle subtitle" "badge badge badge";
      text-align: center;
      gap: 0.5rem;
      .image,
      .title,
      .description,
      .subtitle {
        margin-bottom: 0;
        margin-top: 0;
      }
      .image {
        grid-area: image;
        display: grid;
        place-content: center;
        align-self: var(--menu-tem-image-alignment);
        border-radius: var(--border-radius);
        img {
          height: var(--menu-item-image-height);
        }
        .pi {
          font-size: calc(var(--menu-item-image-height) * 0.6);
          color: var(--text-color-secondary);
        }
      }
      .title {
        grid-area: title;
        color: var(--primary-color);
      }
      .badge {
        grid-area: badge;
        font-size: 0.875rem;
        background-color: var(--yellow-400);
        color: var(--enterprise-color, #1b1680);
        display: inline-flex;
        height: 1.25rem;
        padding: 0 0.25rem;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
        justify-self: center;
        &::before {
          content: "\e936";
          font-family: "primeicons";
          font-size: 0.75rem;
          margin-right: 0.25rem;
        }
        &:empty {
          display: none;
        }
      }
      .subtitle {
        grid-area: subtitle;
        font-size: 0.875rem;
      }
      .description {
        grid-area: description;
        display: none;
        color: var(--text-color);
      }
      .subtitle,
      .description {
        line-height: 1.4;
      }
    }
  }
}
// EXTENDS BREADCRUMBS COMPONENT
.p-breadcrumb {
  background: var(--breadcrumb-background, var(--surface-card));
  border: var(--breadcrumb-border, 1px solid var(--surface-border));
  border-radius: var(--border-radius);
  padding: var(--breadcrumb-padding, 1rem);
  ul li:last-child .p-menuitem-text {
    color: var(--text-color-secondary);
  }
  ul li.p-breadcrumb-chevron::before {
    content: "/";
  }
}
// EXTENDS MENU COMPONENT
.p-menu {
  /* Enlarge menuItem clickable zone */
  --menuitem-link-padding-y: 1rem;
  --menuitem-link-padding-x: 1rem;
  margin: 2px; // smol space to separate overlay and trigger
  &.p-menu-overlay {
    border: var(--menu-border, 1px solid var(--surface-border));
  }
  box-shadow: var(--shadow-1);
  .p-menuitem.p-error {
    --menuitem-link-color: var(--text-color-error);
    --text-hover-color: var(--text-color-error);
    &:not(.p-disabled):hover {
      --background-hover-color: rgba(var(--danger-color-rgb), 0.04);
    }
  }
}
.p-tabmenu {
  --surface-border: var(--primary-100);
  --tabmenu-item-hover-border: var(--primary-600);
}

// * Tabs where the routing is based on params and not different routes
.params-tabmenu .p-tabmenu {
  .p-tabmenu-nav {
    border-color: var(--tabmenu-nav-border-color, transparent);
    .p-tabmenuitem {
      .p-menuitem-link {
        background: var(--tabmenu-link-background, var(--surface-header));
        border-color: var(--tabmenu-link-border-color, var(--surface-border));
        color: var(--tabmenu-link-color, var(--text-color-secondary));
        padding: var(--tabmenu-link-padding, 1.25rem 1rem);
        margin: 0;
      }
      &.p-highlight {
        --tabmenu-item-highlight-border-color: var(--surface-border);
        --tabmenu-item-highlight-color: var(--text-color-secondary);
        .p-menuitem-link {
          &-active {
            --tabmenu-link-border-color: var(--primary-color);
            --tabmenu-link-color: var(--primary-color);
          }
        }
      }
    }
  }
}
// * Bottom menu tab
@media screen and (min-width: 769px) {
  .bottom-bar-tabmenu {
    --tabmenu-nav-justify: normal;
  }
}
.bottom-bar-tabmenu {
  --tabmenu-nav-justify: space-around;
  --tabmenu-padding-bottom: ;
  padding-bottom: env(safe-area-inset-bottom, 0.125rem);
  .p-tabmenu-nav {
    justify-content: var(--tabmenu-nav-justify);
    max-height: var(--header-height);
    .p-tabmenuitem .p-menuitem-link {
      font-weight: 500;
      padding: var(--tabmenu-link-padding, 1.25rem 0.825rem);
      border-color: transparent;
    }
  }
  .badge {
    font-size: 0.875rem;
    background-color: var(--success-color);
    color: white;
    display: flex;
    height: 1.25rem;
    padding: 0 0.25rem;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    &:empty {
      display: none;
    }
  }
}

// Used with table td where the header is passed to a data-header
// displays the header as a prefix to the value for smaller devices
@media screen and (max-width: 960px) {
  .prefix-label::before {
    content: attr(data-label);
    color: var(--text-color-secondary);
  }
}

/**
* Avatars
*/
.unit-avatar {
  background-color: var(--surface-100);
  color: var(--text-color);
  font-weight: 700;
}

.avatar-more {
  background-color: var(--primary-100);
  color: var(--surface-800);
  margin-left: -1rem;
}

.avatar-self {
  background-color: var(--avatar-self-background);
}

.avatar-other {
  background-color: var(--avatar-other-background);
}

.p-avatar-group .avatar {
  border: 2px solid var(--surface-0);
  border-radius: 50%;
  overflow: hidden;
}

.avatar {
  position: relative;
  width: calc(var(--avatar-size) + 4px);
  height: calc(var(--avatar-size) + 4px);

  .avatar-initials {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--avatar-size);
    height: var(--avatar-size);
    color: var(--surface-0);
  }
}

.p-dropdown-sm {
  .p-inputtext {
    font-size: 0.875rem;
    padding: 0.5rem 0.5rem;
  }

  .p-dropdown-trigger {
    width: 2rem;

    .p-dropdown-trigger-icon {
      font-size: 0.75rem;
    }
  }
}

.p-divider {
  &.divider-brand {
    .p-divider-content {
      background-color: var(--brand-background-color);
    }
  }
}

.p-message.p-message-primary {
  background: var(--primary-100);
  border: none;
  border-width: 0 0 0 6px;
  color: var(--text-color);
}

.p-message.p-message-primary .p-message-link {
  color: var(--primary-500);
}

.p-message.p-message-primary .p-message-icon {
  color: var(--primary-500);
}

.p-message.p-message-primary .p-message-close {
  color: var(--primary-500);
}

// Removes focus when clicking on elements - Only show when user tabs
// https://css-tricks.com/the-focus-visible-trick/
:focus:not(:focus-visible) {
  --color-focus: transparent !important;
  --color-focus-shadow: transparent !important;
}

/*
  Invoices statuses
*/
.invoice-status__chip {
  font-size: 0.75rem;
  padding: 0 0.5rem;

  .p-chip-text {
    margin: 0.125rem 0;
    flex-shrink: 0;
  }

  &--draft,
  &--uncollectible {
    background: var(--surface-400);
    color: var(--surface-0);
  }

  &--open {
    background: var(--primary-500);
    color: var(--surface-0);
  }

  &--paid {
    background: var(--green-500);
    color: var(--surface-0);
  }

  &--void {
    background: var(--red-500);
    color: var(--surface-0);
  }
}

.upgrade-badge {
  font-size: 0.875rem;
  background-color: var(--yellow-400);
  color: var(--enterprise-color, #1b1680);
  display: inline-flex;
  height: 1.25rem;
  padding: 0 0.25rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  &::before {
    content: "\e936";
    font-family: "primeicons";
    font-size: 0.75rem;
    margin-right: 0.25rem;
  }
}

.logo-large,
.logo-small {
  max-height: calc(var(--header-height, 4rem) - 1rem);
  border-radius: var(--image-border-radius, 2px);
}

// TEMPLATES LIST (FOR SELECTION)
.templatesListTable {
  min-height: 30rem;
  .grid {
    --grid-min-item-size: 15rem;
    --grid-placement: auto-fill;
    display: grid;
    gap: var(--space, var(--space-3xs, 0.125rem));
    grid-template-columns: 1fr;
    // Container that wraps the empty state
    .p-col {
      grid-column: 1/-1; // Make it stretch to fill the grid
    }
    .template-item {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 8rem auto;
      background: var(--template-background, transparent);
      color: var(--template-color, var(--text-color));
      text-align: left;
      padding: 0;
      border: 1px solid var(--template-border, var(--border-color));
      // Checkbox icon displayed inside the selected template
      &::before {
        content: "\e90a";
        font-family: "primeicons";
        color: var(--primary-color);
        position: absolute;
        right: 0.35rem;
        top: 0.35rem;
        background-color: var(--surface-card);
        display: none;
        border-radius: 50%;
        border: 1px solid var(--primary-color);
        padding: 0.5rem;
      }
      &.isActive {
        --template-border: var(--primary-color);
        &::before {
          display: block;
        }
      }
      &__name {
        background-color: var(--surface-card);
        border-top: 1px solid var(--template-border, var(--border-color));
        padding: 1rem 0.5rem;
        font-size: 0.875em;
        font-weight: 600;
        height: 100%;
      }
      &__preview {
        overflow: hidden;
        height: 100%;
        background-color: var(--surface-50);
        font-size: 12px;
        padding: 0.5rem;
        @include messageHtmlStyles();
        .ql-form-item {
          pointer-events: none;
        }
        & > :first-child {
          margin-top: 0;
        }
        & > :last-child {
          margin-bottom: 0;
        }
        img {
          border-radius: 3px;
        }
      }
    }
  }
  /* When supported by the browser, uses the grid layout else keep a single column */
  @supports (width: min(var(--grid-min-item-size), 100%)) {
    .grid {
      grid-template-columns: repeat(var(--grid-placement), minmax(min(var(--grid-min-item-size), 100%), 1fr));
    }
  }
}

.internal-chat-sidebar {
  .p-sidebar-header {
    min-height: var(--header-height, 5rem);
    border-bottom: 1px solid var(--border-color);
  }
  .p-sidebar-content {
    height: 100%;
    padding: 0 !important;
  }
  .p-sidebar-footer {
    --sidebar-footer-padding: 0;
  }
}

/* Wrapper for displaying template content in edit page */
.template-preview-mode {
  @include messageHtmlStyles();
  .ql-form-item {
    pointer-events: none;
  }
}

/*
  Notifications menu
*/
.notifications-menu {
  max-width: 400px;
  width: 100%;
  border: 1px solid var(--border-color);

  &::before,
  &::after {
    display: none;
  }

  .p-overlaypanel-content {
    padding: 0;
  }

  .notifications-menu__header,
  .notifications-menu__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }

  .notifications-menu__header {
    border-bottom: 1px solid var(--border-color);
  }
  .notifications-menu__footer {
    border-top: 1px solid var(--border-color);
  }

  .notifications-menu__list {
    overflow: auto;
    max-height: 400px;
  }
}

/*
  Notifications list
*/
.notifications-list {
  .notifications-list__header,
  .notifications-list__footer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .notifications-list__header {
    justify-content: space-between;
  }

  .notifications-list__footer {
    justify-content: center;
  }

  .notifications-list__list {
    background-color: var(--surface-card);
    border-radius: var(--border-radius);
    border: 1px solid var(--surface-border);
    overflow: hidden;
  }
}

/*
  Toasts
*/
.p-toast {
  @media screen and (min-width: 30rem) {
    width: 28rem !important;
  }

  &.p-toast-top-right,
  &.p-toast-top-left {
    top: 75px;
  }

  .p-toast-message {
    .p-toast-message-content {
      align-items: center;
    }
    .p-toast-message-icon {
      align-self: baseline;
    }

    .p-toast-button {
      padding: 0.5rem 0.75rem;
    }

    /* All */
    &.p-toast-message-notification,
    &.p-toast-message-info,
    &.p-toast-message-warn,
    &.p-toast-message-error,
    &.p-toast-message-success {
      background: #fff;
      border: none;
      color: var(--text-color);
      box-shadow: var(--shadow-1);

      .p-toast-icon-close {
        align-self: baseline;
        color: var(--text-color-secondary);
        background-color: rgba(var(--secondary-color-rgb), 0.08);
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 2px;
      }
    }

    .p-toast-detail {
      line-height: 1.5;
      margin: var(--_mt, 0) 0 0 0 !important;
      &:not(:only-child) {
        --_mt: 0.25rem;
      }
    }

    &.p-toast-message-info {
      border-left: 4px solid rgba(var(--info-color-rgb), 0.8);

      .p-toast-message-icon {
        color: rgba(var(--info-color-rgb), 0.8);
      }
    }
    &.p-toast-message-success {
      border-left: 4px solid rgba(var(--success-color-rgb), 0.8);

      .p-toast-message-icon {
        color: rgba(var(--success-color-rgb), 0.8);
      }
    }
    &.p-toast-message-warn {
      border-left: 4px solid rgba(var(--warning-color-rgb), 0.8);

      .p-toast-message-icon {
        color: rgba(var(--warning-color-rgb), 0.8);
      }
    }
    &.p-toast-message-error {
      border-left: 4px solid rgba(var(--danger-color-rgb), 0.8);

      .p-toast-message-icon {
        color: rgba(var(--danger-color-rgb), 0.8);
      }
    }
  }
}

/*
  Emoji panel
*/
.emoji-panel {
  margin-top: -15px !important;

  &:before,
  &:after {
    content: "";
    border-width: 0 !important;
  }

  .p-overlaypanel-content {
    padding: 0;
  }
}

image-cropper img {
  display: inline;
}

/*
  Inbox records table
*/
.inbox__records-table {
  .p-datatable-header {
    // padding: 0.5rem 0rem !important;
    border: none;
    background-color: transparent;
    border-radius: var(--border-radius);
  }
  .p-sortable-column-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  &-menu {
    --menu-width: calc(100% - 0.5rem);
    --menuitem-link-padding-y: 0.5rem;
    font-size: 0.875rem;
    @media screen and (min-width: 30em) {
      --menu-width: 25em;
    }
  }
}

.generic-actions-menu {
  --menu-border: 1px solid var(--border-color);
  --menuitem-link-padding-y: 0.75rem;
  font-size: 0.875rem;
  @media screen and (min-width: 25em) {
    --menu-width: 22em;
  }
}

.record-attachments-table {
  .p-datatable-header {
    border: none;
  }
}
/*
  Inbox filterd
*/
p-autocomplete {
  timesicon {
    margin-left: 0.5rem;

    svg {
      path {
        fill: var(--text-color-secondary);
      }
    }
  }
}

/**
 *
 * Applies styles based on the Urgency.
 * Class i provided from the getColorFromRecordUrgency pipe
 */

.urgency-color-warning {
  color: var(--urgency-color-warning, var(--danger-color));
  .p-progressbar-value {
    --primary-color: var(--danger-color);
  }
}

.urgency-color-default {
  color: var(--urgency-color-base, inherit);
  .p-progressbar-value {
    --primary-color: var(--green-600);
  }
}

.import-contacts-results-panel {
  .p-panel-header {
    padding: 0 var(--space-xs, 0.5rem) !important;
    background: transparent;
    border-width: 0px 0px 1px 0px;
    border-color: var(--border-color);
    .p-panel-title {
      font-weight: 500;
    }
  }
  .p-panel-content {
    padding: 0.75rem var(--space-xs, 0.5rem) !important;
    background-color: transparent;
    border: none;
  }
}

.dashboard-table-filter-panel {
  max-width: 300px;
  width: 100%;
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  &::before,
  &::after {
    display: none;
  }
  .p-overlaypanel-content {
    padding: 0;
  }
}

.filter-block {
  .filter-block__header {
    font-weight: 400;
  }
  .filter-block__content {
    border: 1px solid var(--surface-border);
    border-radius: var(--border-radius);
    margin-top: 0.25rem;
    .field-checkbox {
      border-bottom: 1px solid var(--surface-border);
      margin-bottom: 0;
      .filter-radio-button {
        display: none;
      }
      label {
        width: 100%;
        margin-left: 0;
        font-weight: 500;
        background-color: var(--filter-radio-label-background, transparent);
        color: var(--filter-radio-label-color, var(--text-color));
        padding: 1rem 0.5rem;
        cursor: pointer;
        --highlight-background-color: rgba(var(--primary-color-rgb), 0.1);
        --text-highlight-color: var(--primary-color);
        &.isActive {
          --filter-radio-label-background: var(--highlight-background-color);
          --filter-radio-label-color: var(--text-highlight-color);
        }
      }
    }
  }
}

// Tokenize sidebar component for easier styling
.p-sidebar {
  --sidebar-padding: 1.25rem;
  --sidebar-footer-border-top: 1px solid var(--surface-border);
  --sidebar-content-radius: 0;
  --sidebar-content-padding: var(--sidebar-padding);
  --sidebar-content-background: var(--surface-c);

  .p-sidebar-header {
    padding: var(--sidebar-header-padding, var(--sidebar-padding));
    background: var(--sidebar-header-background, var(--surface-header));
    border-bottom: var(
      --sidebar-header-border-bottom,
      1px solid var(--sidebar-header-border-color, var(--surface-border))
    );
  }
  .p-sidebar-content {
    background: var(--sidebar-content-background, var(--surface-overlay));
    padding: var(
      --sidebar-content-padding,
      0 var(--sidebar-padding, 1.5rem) var(--sidebar-padding, 1.5rem) var(--sidebar-padding, 1.5rem)
    );
  }

  .p-sidebar-footer {
    padding: var(--sidebar-footer-padding, var(--sidebar-padding));
    background: var(--sidebar-footer-background, var(--surface-header));
    border-top: var(
      --sidebar-footer-border-bottom,
      1px solid var(--sidebar-footer-border-color, var(--surface-border))
    );
  }
}

.user-role-dropdown {
  background-color: rgba(var(--primary-color-rgb), 0.08);
  border: none;
  .p-dropdown-label,
  .p-dropdown-trigger {
    color: var(--primary-color);
  }
}
.user-role-dropdown-panel {
  --text-highlight-color: var(--primary-800);
  --highlight-background-color: rgba(var(--primary-color-rgb), 0.1);
  --background-hover-color: rgba(var(--primary-color-rgb), 0.1);
  width: 100%;
  border: 1px solid var(--border-color);
  margin-top: 5px;
  @media screen and (min-width: 20em) {
    min-width: 16rem;
  }
  .p-highlight:not(:hover) {
    --highlight-background-color: transparent;
  }
  .p-disabled {
    background-color: var(--surface-d) !important;
  }
}
.readonly-dropdown {
  .p-dropdown,
  .p-dropdown-label {
    cursor: not-allowed;
  }
  .p-dropdown-trigger {
    display: none;
  }
}

.unit-sidebar-menu {
  box-shadow: none;
  width: 100%;
  border: 1px solid var(--surface-border);
  padding: 0;
  .p-menu-list {
    overflow: hidden;
    border-radius: inherit;
  }
}

.description-list {
  margin: 0;
  .description-item {
    padding: 1rem 0.5rem;
    gap: 0.5rem;
    display: grid;
    grid-template-columns: 1fr;
    @media screen and (min-width: 768px) {
      grid-template-columns: 16rem 1fr;
    }
    &:not(:first-child) {
      border-top: 1px solid var(--surface-border);
    }
    dt {
      color: var(--text-color-secondary);
    }
    dd {
      color: var(--text-color);
      margin-left: 0;
      @media screen and (min-width: 768px) {
        margin-left: revert;
      }
    }
  }
}

.generic-steps {
  &:not(.p-readonly) {
    .p-menuitem-link {
      cursor: pointer;
      &:hover {
        background-color: var(--g-steps-link-bg, var(--surface-c));
      }
    }
  }
  .p-steps-item {
    &.p-highlight .p-steps-title {
      color: var(--primary-800);
    }
    &:first-child::before {
      width: 50%;
      left: 50%;
    }
    &:last-child::before {
      width: 50%;
      right: 0;
    }
    .p-menuitem-link {
      width: 100%;
      --highlight-background-color: var(--primary-700);
      --text-highlight-color: var(--primary-50);
      .p-steps-number {
        font-size: 0.875rem;
        min-width: 1.5rem;
        height: 1.5rem;
        border: 1px solid var(--border-color);
      }
    }
  }
}

.filesSplitButtonMenu {
  --menu-width: 100%;
  font-size: 0.875rem;
  width: clamp(18rem, var(--menu-width), 100vw);
  border: 1px solid var(--surface-300) !important;
  @media screen and (min-width: 30em) {
    --menu-width: 22em;
  }
}

.provider-switcher-menu {
  --provider-header-height: 4rem;
  min-height: var(--provider-header-height);
  padding: 0;
  border: none;
  background-color: var(--surface-header);
  border-radius: 0;
  border-bottom: 1px solid var(--surface-border);

  .provider-menu-label {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0rem 0.5rem;
    grid-template-areas:
      "label_icon label_title"
      "label_icon label_subtitle";
    img {
      grid-area: label_icon;
      align-self: center;
    }
    b {
      font-weight: 500;
      grid-area: label_title;
    }
    span {
      grid-area: label_subtitle;
      font-size: 0.875rem;
      color: var(--text-color-secondary);
    }
  }
  .p-submenu-list {
    width: auto;
    max-width: var(--menu-width);
    border: 1px solid var(--border-color) !important;
    border-radius: var(--border-radius);
    font-size: 0.875rem;

    @media screen and (min-width: 30em) {
      --menu-width: 20rem;
    }
  }
}

.contactTagsAutocomplete {
  --badge-border-color: var(--primary-500);
  --badge-border-style: solid;
  --badge-background-color: rgba(var(--primary-color-rgb), 0.02);
  --badge-color: var(--primary-500);
  .p-autocomplete-multiple-container {
    column-gap: 1px;
    box-shadow: none !important;
    .p-autocomplete-token {
      --border-radius: 2rem;
      margin-right: 2px;
      margin-top: 2px;
      display: inline-block;
      background-color: var(--badge-background-color, var(--surface-200));
      border: 1px var(--badge-border-style, solid) var(--badge-border-color, transparent);
      color: var(--badge-color, #fff);
      font-size: 0.8rem;
      padding: 0.3rem 0.55rem;
      line-height: 1;
    }
  }
  .p-autocomplete-dropdown {
    background-color: #fff;
    color: var(--primary-color);
    border-left-width: 0;
    &:enabled:hover {
      background: rgba(var(--primary-color-rgb), 0.04);
      color: var(--primary-color);
    }
  }
  :not(.p-focus) ~ .p-autocomplete-dropdown {
    border-color: var(--border-color);
  }
}
.contactTagsAutocompletePanel {
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow);
  margin-top: 2px;
  margin-bottom: 2px;
}

.match-columns-panel {
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow);
  margin-top: 4px;
  margin-bottom: 4px;
}

.generic-tooltip {
  opacity: 0.9 !important;
  --tooltip-background-color: #000;
  --tooltip-color: #fff;
  font-size: 0.875rem;
  @media screen and (min-width: 30em) {
    max-width: 20rem !important;
  }
  .p-tooltip-text {
    padding: 0.25rem 0.5rem;
    outline: 1px solid var(--tooltip-background-color);
    border-radius: 3px;
  }
}

.generic-overlay-panel {
  &.no-offset {
    --overlay-top-offset: 0;
  }
  max-width: 400px;
  width: 100%;
  border: 1px solid var(--border-color);
  margin-top: var(--overlay-top-offset, -2.6rem) !important; //
  margin-bottom: var(--overlay-top-offset, -2.6rem) !important; //
  &::before,
  &::after {
    display: none;
  }
  .p-overlaypanel-content {
    padding: 0;
  }
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &__main {
    padding: 0.75rem;
  }
}

.tagsFilterPanel {
  --badge-background-color: rgba(var(--primary-color-rgb), 0.02);
  --badge-color: var(--primary-500);
  --badge-border-color: var(--primary-500);
  --overlay-top-offset: 0;
  .p-listbox {
    --listbox-wrapper-offset: 25rem;
    .p-listbox-empty-message {
      padding: 0.75rem 0.375rem 0 0.375rem;
      color: var(--text-color-secondary);
    }
    .field {
      margin-bottom: 0.15rem;
    }
    .p-listbox-header {
      padding: 0;
    }
    .p-listbox-footer {
      padding-top: 0.75rem;
      border-top: 1px solid var(--border-color);
    }
    .p-listbox-list-wrapper {
      overscroll-behavior: contain;
      .p-listbox-list {
        padding: 0 !important;
        max-height: clamp(10rem, 100vh - var(--listbox-wrapper-offset, 15rem), 30rem);
        --background-hover-color: var(--surface-c);
        --highlight-background-color: var(--surface-c);
        --chips-token-size: 0.85rem;

        .p-listbox-item {
          padding: 0.75rem 0.375rem;
          span {
            line-height: 1;
            font-size: var(--chips-token-size, 0.85rem);
            padding: 0.3rem 0.55rem;
            font-weight: 500;

            background-color: var(--badge-background-color, var(--surface-600));
            color: var(--badge-color, #fff);
            border-radius: 2rem;
            border: 1px var(--badge-border-style, solid) var(--badge-border-color, transparent);
          }
          &.p-highlight:not(:hover) {
            --highlight-background-color: transparent;
            --text-highlight-color: inherit;
          }
        }
      }
    }
    .p-autocomplete {
      width: 100%;
      --color-focus-shadow: var(--primary-100);
      --color-focus: var(--primary-color);
    }
    .p-autocomplete-clear-icon {
      top: 0.375rem;
      right: 0.75rem;
      margin-top: 0;
      background-color: var(--surface-d);
      border: 1px solid var(--surface-d);
      border-radius: 50%;
      line-height: 0;
      padding: 0.3rem;
    }
    .p-autocomplete-input-token {
      input {
        font-size: var(--chips-token-size, 0.85rem);
      }
    }
    .p-autocomplete-token {
      font-size: var(--chips-token-size, 0.85rem);
      padding: 0.3rem 0.55rem;
      line-height: 1;
      font-weight: 500;
      background-color: var(--badge-background-color, var(--surface-600));
      color: var(--badge-color, #fff);
      border: 1px var(--badge-border-style, solid) var(--badge-border-color, transparent);
      border-radius: 2rem;
    }
    .p-autocomplete-multiple-container {
      gap: 0.5rem 0rem;
      padding: 0.375rem 1.3rem 0.375rem 0.375rem;
      width: 100%;
      max-height: 5lh;
      overflow-y: auto;
      &:not(.p-disabled).p-focus {
        box-shadow: 0 0 0 0.2rem var(--color-focus-shadow);
      }
    }
  }
}

.filter-active {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
  border: 1px solid transparent !important;
}
.inbox__filters-panel {
  --overlay-top-offset: 0;
}

.p-datepicker table td > span.p-highlight {
  outline: 1px solid;
}

.generic-menu {
  --menu-width: 100%;
  border: 1px solid var(--border-color);
  .p-menu-list .p-menuitem-link {
    font-size: 0.875rem;
    --menuitem-link-padding-y: 0.75rem;
  }
  @media screen and (min-width: 20em) {
    --menu-width: 18em;
  }
}

.record-title-button.p-button {
  all: unset;
  color: inherit !important;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: -4px;
  border-radius: 3px;
  display: flex;
  align-items: center;

  .p-button-label {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }

  .p-button-icon {
    opacity: 0;
  }
  &.p-button.p-button:enabled:hover {
    background-color: transparent;
  }
  &.clickable {
    cursor: pointer;
    &.p-button.p-button:enabled:hover {
      background-color: var(--surface-d);
      .p-button-icon {
        opacity: 1;
      }
    }
  }
}

.completion-item-button.p-button {
  all: unset;
  color: inherit !important;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: -4px;
  border-radius: 3px;
  display: flex;
  align-items: center;

  .p-button-label {
    overflow: hidden;
    font-weight: 600;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }

  .p-button-icon {
    opacity: 0;
  }
  &.p-button.p-button:enabled:hover {
    background-color: transparent;
  }
  &.clickable {
    cursor: pointer;
    color: var(--text-color);
    &.p-button.p-button:enabled:hover {
      background-color: var(--surface-d);
      color: var(--text-color);
      .p-button-icon {
        opacity: 1;
      }
    }
  }
}

.record-create-message {
  padding: 0.5rem;
  &.p-inline-message-info {
    border: 1px solid var(--info-color);
  }
  .p-icon-wrapper {
    display: contents;
    .p-inline-message-icon {
      --icon-size: 1.3rem;
      width: var(--icon-size);
      height: var(--icon-size);
      align-self: baseline;
      margin-top: 1px;
    }
  }
}

.generic-message {
  .p-icon-wrapper {
    flex-shrink: 0;
    align-self: baseline;
    line-height: inherit;
    display: block;
    .p-inline-message-icon {
      margin-top: 3px;
    }
  }
}

@media screen and (max-width: 50rem) {
  .searchRecipientsPanel__main {
    --panel-border-width: calc(1px * 2);
    --right-panel-minimum-width: auto !important;
    --left-panel-minimum-width: min(
      40rem,
      calc(100vw - var(--fluid-padding) * 2 - var(--card-padding, 2rem) * 2 - var(--panel-border-width))
    ) !important;
  }
  .searchRecipientsPanel__groups {
    display: none !important;
  }
}

.searchRecipientsPanel {
  max-width: min-content;
  --overlay-top-offset: 4px;
  .searchRecipientsPanel__main {
    // There are 2 panels inside (users and groups)
    border-radius: var(--border-radius);
    overflow: auto;
    background-color: var(--surface-c);
    --left-panel-minimum-width: 25rem;
    --right-panel-minimum-width: 25rem;
    --panel-header-height: 4rem;
    --panel-height: min(400px, 60vh - var(--listbox-wrapper-offset, 15rem));
    display: grid;
    grid-template-rows: var(--panel-height);
    grid-template-columns: minmax(var(--left-panel-minimum-width), 1fr) var(--right-panel-minimum-width);
    grid-template-areas: "users groups";
    &.noGroups {
      --right-panel-minimum-width: auto;
    }
    .p-listbox {
      background-color: transparent;
      display: flex;
      flex-direction: column;
      width: 100%;
      .p-listbox-header {
        padding: 0.75rem 1rem;
      }
      .p-listbox-list-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .p-listbox-list {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          .p-listbox-empty-message {
            flex-grow: 1;
            display: flex;
            padding: 0;
          }
        }
      }
    }
    .searchRecipientsPanel__users,
    .searchRecipientsPanel__groups {
      display: flex;
      flex-direction: column;
      header {
        padding: 1rem;
        background-color: var(--surface-card);
        border-bottom: 1px solid var(--surface-border);
        text-transform: uppercase;
      }
      p-listbox {
        flex-grow: 1;
        overflow: auto;
        display: flex;
      }
    }
    .searchRecipientsPanel__users {
      grid-area: users;
    }
    .searchRecipientsPanel__groups {
      grid-area: groups;
      border-left: 1px solid var(--surface-border);
      .p-listbox-item {
        padding: 0;
        margin: 0.625rem 1.125rem 0rem 1.125rem;
        &.p-highlight {
          background-color: transparent !important;
        }
        &:hover {
          background-color: transparent !important;
          --group-item-background: var(--background-hover-color);
        }
        .group-item {
          border: 1px solid var(--surface-border);
          width: 100%;
          padding: 1rem;
          background-color: var(--group-item-background, var(--surface-card));
          gap: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        ui-badge > span {
          display: block;
          --border-radius: 4px;
        }
      }
    }
  }
}

.record-update-expiration-panel {
  border: 1px solid var(--border-color) !important;
}

.form-item-filter-dropdown {
  --text-highlight-color: var(--primary-800);
  --highlight-background-color: rgba(var(--primary-color-rgb), 0.1);
  --background-hover-color: rgba(var(--primary-color-rgb), 0.1);
  width: 100%;
  border: 1px solid var(--border-color);
  margin-top: 5px;
  @media screen and (min-width: 20em) {
    min-width: 16rem;
  }
}

.batchFormAnswersModal {
  &:not(.p-dialog-maximized) {
    .p-dialog-content {
      display: flex;
      flex-direction: column;
    }
  }
}

.form-item-completion-table {
  .p-paginator-bottom {
    display: none;
  }
}
